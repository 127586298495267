@import '../../app/variable.scss';
/* ============================ form START ================================================================= */
.form {
   width: 100%;
   display: flex;
   flex-direction: column;
   &.is-hidden {
      display: none;
   }
   &__inner {
      background-color: $bg-color;
      padding: 10rem 0;
      @media screen and (max-width: 666px) {
         padding: 5rem 2rem;
      }
   }
   &__wrapper {
      display: flex;
      flex-direction: column;
      max-width: 86rem;
      width: 100%;
      margin: 0 auto;
   }
   &__title {
      position: relative;
      margin-bottom: 5rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 2.5rem;
      }
      &::after {
         content: "";
         position: absolute;
         bottom: -2.4rem;
         left: 50%;
         transform: translate(-50%, 0);
         width: 10rem;
         height: 2px;
         background-color: $accent;
         @media screen and (max-width: 666px) {
            bottom: -1.2rem;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 4.8rem;
         line-height: 5.6rem;
         text-align: center;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 4rem;
            line-height: 5rem;
         }
      }
   }
   &__text {
      max-width: 64rem;
      width: 100%;
      margin-bottom: 5rem;
      align-self: center;
      @media screen and (max-width: 666px) {
         margin-bottom: 2.5rem;
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         text-align: center;
         color: $primary-text-color;
      }
   }
   &__textarea {
      resize: none;
      padding: 2.4rem;
      width: 100%;
      height: 16.8rem;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: $title-color;
      outline: none;
      @media screen and (max-width: 666px) {
         padding: 1rem;
         height: 13rem;
      }
      &::placeholder {
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $primary-text-color;
      }
   }
   &__list {
      display: flex;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
      @media screen and (min-width: 667px) {
         flex-wrap: wrap;
         margin: -2.4rem 0 2.4rem -1.6rem;
      }
   }
   &__item {
      position: relative;
      @media screen and (max-width: 666px) {
         margin-bottom: 1.6rem;
      }
      & input {
         font-size: 1.8rem;
         line-height: 2.1rem;
         width: 100%;
         height: 7.2rem;
         padding: 2.4rem 1.2rem 2.4rem 6.2rem;
         color: $title-color;
         outline: none;
         @media screen and (max-width: 666px) {
            height: 5rem;
            padding: 1rem 1rem 1rem 3rem;
         }
         &::placeholder {
            font-size: 1.8rem;
            line-height: 2.1rem;
            color: $primary-text-color;
         }
      }
      &.error {
         & input {
            border: solid 1px $accent;
         }
         & .form__icon ~ span {
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: $accent;
            opacity: 1;
         }
      }
      &.has-product-input {
         &:nth-child(3) {
            @media screen and (min-width: 667px) {
               margin: 2.4rem 0 0 1.6rem;
               width: calc(100% / 2 - 1.6rem);
            }
         }
         &:nth-child(4) {
            @media screen and (min-width: 667px) {
               margin: 2.4rem 0 0 1.6rem;
               width: calc(100% / 2 - 1.6rem);
            }
         }
         &:nth-child(5) {
            width: 100%;
            @media screen and (min-width: 667px) {
               margin: 2.4rem 0 0 1.6rem;
            }
         }
      }
      &:nth-child(1) {
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
            width: calc(100% / 2 - 1.6rem);
         }
      }
      &:nth-child(2) {
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
            width: calc(100% / 2 - 1.6rem);
         }
      }
      &:nth-child(3) {
         width: 100%;
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
         }
      }
      &:nth-child(4) {
         width: 100%;
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
         }
      }
   }
   &__btn {
      //align-self: center;
      &.button {
         min-width: 21.5rem;
         max-width: none;
         width: auto;
         flex: 0 1 auto;
         align-self: end;
         @media screen and (max-width: 666px) {
            min-width: 14rem;
            max-width: none;
            width: auto;
            padding: 1rem 2rem;
            align-self: center;
            & > * {
               font-size: 1.5rem;
               line-height: 2rem;
            }
         }
      }
      &_success.button {
         align-self: center;
      }
   }
   &__icon {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translate(0, -50%);
      width: 1.8rem;
      height: 1.8rem;
      @media screen and (max-width: 666px) {
         width: 1.5rem;
         height: 1.5rem;
         left: 1rem;
      }
      & > * {
         width: 1.8rem;
         height: 1.8rem;
         @media screen and (max-width: 666px) {
            width: 1.5rem;
            height: 1.5rem;
         }
      }
      & ~ span {
         position: absolute;
         left: 0;
         bottom: -1.7rem;
         opacity: 0;
      }
   }
}

.success {
   display: flex;
   flex-direction: column;
   align-items: center;
   &[data-hidden="is-hidden"] {
      display: none;
   }
}
/* ============================ form END ================================================================= */
