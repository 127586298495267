@charset "UTF-8";
/* ============================ styles from placeholder START ================================================================= */
/* ============================ styles from placeholder END ================================================================= */
.breadcrumbs {
  padding-top: 2rem; }
  .breadcrumbs > * {
    display: flex;
    align-items: center; }
    .breadcrumbs > * > * {
      position: relative; }
      .breadcrumbs > * > *:not(:last-child) {
        margin-right: 1.5rem; }
        .breadcrumbs > * > *:not(:last-child)::after {
          content: "/";
          position: absolute;
          top: 50%;
          right: -0.8rem;
          transform: translate(0, -50%);
          color: #8395A7;
          transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
        .breadcrumbs > * > *:not(:last-child) > * {
          font-family: "RobotoCondensed";
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: #8395A7;
          transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
          .breadcrumbs > * > *:not(:last-child) > *:hover {
            color: #E44A3C; }
      .breadcrumbs > * > * > * {
        font-family: "RobotoCondensed";
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #C8D6E5;
        transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }

/* ============================ fortress START ================================================================= */
.info-fortress__wrapper {
  margin: 5rem 0 10rem; }
  @media screen and (max-width: 666px) {
    .info-fortress__wrapper {
      margin: 5rem 0; } }

@media screen and (min-width: 667px) {
  ¬
.info-fortress + .form__container {
    margin-bottom: 5rem; } }

/* ============================ fortress END ================================================================= */
