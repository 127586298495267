@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ commercial START ================================================================= */
.info-commercial {
   &__text {
      max-width: 86rem;
      width: 100%;
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      @extend %title-h3;
      text-align: center;
      & > * {
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
   }
}

.choose-commercial {
   padding-bottom: 10rem;
   @media screen and (max-width: 666px) {
      padding-bottom: 5rem;
   }
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}
/* ============================ commercial END ================================================================= */