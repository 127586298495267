@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ ornamental-iron START ================================================================= */
.aesthetic-ornamental-iron {
   &__text {
      max-width: 67rem;
      width: 100%;
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      & > * {
         font-size: 1.8rem;
         line-height: 2.1rem;
         text-align: center;
         color: #57606F;
      }
   }
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}

.aesthetic {
   &__wrapper {
      padding: 4rem 1rem 0;
      @media screen and (max-width: 666px) {
         padding: 2rem 1rem 0;
      }
   }
   &__img {
      position: relative;
      min-height: 20rem;
      max-height: 20rem;
      height: 1px;
      width: 100%;
      &::before {
         content: "";
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         width: 100%;
         height: 100%;
         background-color: #000;
         opacity: 0.4;
      }
   }
   &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 4rem;
      height: 4rem;
   }
   &__list {
      display: flex;
      &:not(:last-child) {
         margin-bottom: 7rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 40.9rem;
      &:not(:last-child) {
         @media screen and (min-width: 667px) {
            margin-right: 11.6rem;
         }
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
   }
   &__title {
      @extend %title-h3;
      text-align: center;
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
   }
   &__text {
      margin-top: auto;
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         text-align: center;
      }
   }
}
/* ============================ ornamental-iron END ================================================================= */
