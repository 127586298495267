@import '../../app/variable.scss';
.input{
	&__elem{
		width: 10rem;
	}
}
//@@include( 'develop/components/input/input.html', {
//"type": "text",
//"mod": "input_recovery",
//"isDate": "",
//"isSelect": "",
//"hasInformation": "",
//"confirmNumber": "",
//"confirmSMS": "",
//"placeholder": "+7(9XX) XXX - XX - XX",
//"name": "tel",
//"label": "Номер мобильного"
//})