/* ============================ styles from placeholder START ================================================================= */
.template-title-2 > * {
  font-family: "RobotoCondensed";
  font-size: 3.6rem;
  line-height: 4.2rem;
  color: #2F3542; }

.template-text-2 > * {
  font-family: "RobotoCondensed";
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #2F3542; }
  @media screen and (max-width: 666px) {
    .template-text-2 > * {
      font-size: 1.8rem;
      line-height: 2.2rem; } }

.template-inner .breadcrumbs > * > *:not(:last-child) {
  margin-right: 1.5rem; }
  .template-inner .breadcrumbs > * > *:not(:last-child)::after {
    color: #ffffff; }
  .template-inner .breadcrumbs > * > *:not(:last-child) > * {
    color: #ffffff; }
    .template-inner .breadcrumbs > * > *:not(:last-child) > *:hover {
      color: #E44A3C; }

.template-inner .breadcrumbs > * > * > * {
  color: #8395A7; }

/* ============================ styles from placeholder END ================================================================= */
html {
  font-size: 10px; }
  @media screen and (min-width: 667px) {
    html {
      font-size: 5px; } }
  @media screen and (min-width: 992px) {
    html {
      font-size: 6px; } }
  @media screen and (min-width: 1024px) {
    html {
      font-size: 7px; } }
  @media screen and (min-width: 1280px) {
    html {
      font-size: 8px; } }
  @media screen and (min-width: 1366px) {
    html {
      font-size: 9px; } }
  @media screen and (min-width: 1680px) {
    html {
      font-size: 10px; } }

* {
  padding: 0;
  margin: 0;
  border: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  color: #8395A7;
  font-family: "Roboto", sans-serif;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.no-scroll {
    overflow: hidden; }

input,
button,
textarea,
optgroup,
select {
  font-family: "Roboto";
  font-size: inherit; }

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit; }

picture {
  display: block; }

p {
  margin: 0;
  padding: 0; }

a {
  cursor: pointer;
  display: inline-block;
  color: inherit; }

a:link,
a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none; }

ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0; }

blockquote {
  margin: 0;
  padding: 0; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.app {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.main {
  flex: 1 1 auto; }

[class*="__container"] {
  max-width: 179rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem; }

@media screen and (min-width: 667px) {
  .popup__header {
    position: absolute;
    width: 50vw;
    height: 100vh;
    right: 0; } }

@media screen and (min-width: 667px) {
  .popup__header .fancybox-slide--html,
  .popup__header .fancybox-close-small {
    top: 50%;
    left: -3.2rem;
    width: 6.4rem;
    height: 6.4rem;
    background-color: rgba(200, 214, 229, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2rem);
    border-radius: 5rem; } }

@media screen and (min-width: 667px) {
  .popup__header .fancybox-button::before {
    content: "";
    position: absolute;
    top: 2rem;
    left: 3rem;
    height: 2.2rem;
    width: 1px;
    background: #ffffff;
    transform: rotate(45deg); }
  .popup__header .fancybox-button::after {
    content: "";
    position: absolute;
    top: 2rem;
    left: 3rem;
    height: 2.2rem;
    width: 1px;
    background: #ffffff;
    transform: rotate(-45deg); } }

@media screen and (min-width: 667px) {
  .popup__header .fancybox-button svg {
    display: none; } }

@media screen and (min-width: 667px) {
  .popup__header.fancybox-content {
    overflow: visible; } }

@media screen and (max-width: 666px) {
  .popup__header.fancybox-content {
    padding: 0; } }

.popup__gallery {
  max-width: 116rem;
  width: 100%;
  padding: 0;
  background-color: transparent; }
  @media screen and (max-width: 666px) {
    .popup__gallery {
      padding: 1rem; } }
  .popup__gallery.fancybox-content {
    overflow: visible; }
    @media screen and (max-width: 666px) {
      .popup__gallery.fancybox-content {
        padding: 0; } }
  .popup__gallery_img {
    height: 63.7rem;
    margin-bottom: 2rem; }
    @media screen and (max-width: 666px) {
      .popup__gallery_img {
        height: 30rem; } }
  .popup__gallery.popup__gallery_content {
    padding: 3rem;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (max-width: 666px) {
      .popup__gallery.popup__gallery_content {
        padding: 1rem; } }
    .popup__gallery.popup__gallery_content > * {
      max-width: 86rem;
      width: 100%; }
  .popup__gallery .fancybox-button {
    top: -4rem; }
    @media screen and (min-width: 667px) {
      .popup__gallery .fancybox-button {
        width: 4.4rem;
        height: 4.4rem;
        right: -5.5rem; }
        .popup__gallery .fancybox-button::before {
          content: "";
          position: absolute;
          transform: rotate(45deg) translate(-50%, -50%);
          top: 38%;
          left: 25%;
          height: 3.2rem;
          width: 0.2rem;
          background: #ffffff; }
        .popup__gallery .fancybox-button::after {
          content: "";
          position: absolute;
          top: 36%;
          left: 75%;
          height: 3.2rem;
          width: 0.2rem;
          background: #ffffff;
          transform: rotate(-45deg) translate(-50%, -50%); } }
  @media screen and (min-width: 667px) {
    .popup__gallery .fancybox-button svg {
      display: none; } }

.fancybox-button--arrow_left {
  position: relative;
  width: 6.4rem !important;
  height: 6.4rem !important;
  margin: 0;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2rem);
  border-radius: 5rem;
  padding: 0 !important; }
  @media screen and (min-width: 769px) {
    .fancybox-button--arrow_left {
      left: 8rem !important; } }
  @media screen and (max-width: 666px) {
    .fancybox-button--arrow_left {
      display: none; } }
  .fancybox-button--arrow_left div {
    display: none; }
  .fancybox-button--arrow_left::before {
    content: "";
    position: absolute;
    top: 57%;
    left: 44%;
    width: 1rem;
    height: 1px;
    transform: rotate(45deg);
    background-color: #ffffff; }
  .fancybox-button--arrow_left::after {
    content: "";
    position: absolute;
    top: 45%;
    left: 43%;
    width: 1rem;
    height: 1px;
    transform: rotate(-45deg);
    background-color: #ffffff; }

.fancybox-button--arrow_right {
  position: relative;
  width: 6.4rem !important;
  height: 6.4rem !important;
  margin: 0;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2rem);
  border-radius: 5rem;
  padding: 0 !important; }
  @media screen and (min-width: 769px) {
    .fancybox-button--arrow_right {
      right: 8rem !important; } }
  @media screen and (max-width: 666px) {
    .fancybox-button--arrow_right {
      display: none; } }
  .fancybox-button--arrow_right div {
    display: none; }
  .fancybox-button--arrow_right::before {
    content: "";
    position: absolute;
    top: 45%;
    left: 43%;
    width: 1rem;
    height: 1px;
    transform: rotate(45deg);
    background-color: #ffffff; }
  .fancybox-button--arrow_right::after {
    content: "";
    position: absolute;
    top: 57%;
    left: 44%;
    width: 1rem;
    height: 1px;
    transform: rotate(-45deg);
    background-color: #ffffff; }

/* ============================ template START ================================================================= */
.template-inner {
  position: relative;
  background: #000000; }

.template-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .template-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); }

.template-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem 0 10rem; }
  @media screen and (max-width: 666px) {
    .template-wrapper {
      padding: 2rem 0 5rem; } }

.template-content {
  background: rgba(166, 166, 166, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2rem);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0.6rem;
  padding: 5rem 7.5rem;
  max-width: 86rem;
  width: 100%; }
  @media screen and (max-width: 666px) {
    .template-content {
      padding: 2rem; } }
  .template-content > p {
    font-size: 1.6rem;
    line-height: 2.6rem;
    text-align: center;
    color: #ffffff; }
    .template-content > p:not(:last-child) {
      margin-bottom: 2rem; }
    .template-content > p > strong {
      font-weight: 500; }
  .template-content > h2 {
    font-family: "RobotoCondensed";
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-align: center;
    color: #ffffff; }
    .template-content > h2:not(:last-child) {
      margin-bottom: 2rem; }

.template-title {
  margin-bottom: 5rem; }
  @media screen and (max-width: 666px) {
    .template-title {
      margin-bottom: 2rem; } }
  .template-title > * {
    font-family: "RobotoCondensed";
    font-size: 4.8rem;
    line-height: 5.6rem;
    color: #ffffff;
    text-align: center; }
    @media screen and (max-width: 666px) {
      .template-title > * {
        font-size: 4rem;
        line-height: 5rem; } }

.template-title-text__box {
  max-width: 86rem;
  width: 100%; }
  .template-title-text__box:not(:last-child) {
    margin-bottom: 7rem; }
    @media screen and (max-width: 666px) {
      .template-title-text__box:not(:last-child) {
        margin-bottom: 3rem; } }

.template-title-text__title {
  position: relative;
  margin-bottom: 5rem; }
  @media screen and (max-width: 666px) {
    .template-title-text__title {
      margin-bottom: 2.5rem; } }
  .template-title-text__title::after {
    content: "";
    position: absolute;
    bottom: -2.4rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 10rem;
    height: 2px;
    background-color: #E44A3C; }
    @media screen and (max-width: 666px) {
      .template-title-text__title::after {
        bottom: -1.2rem; } }
  .template-title-text__title > * {
    font-family: "RobotoCondensed";
    font-size: 3.6rem;
    line-height: 4.2rem;
    text-align: center;
    color: #2F3542; }

.template-title-text__text {
  align-self: center; }
  .template-title-text__text > * {
    font-size: 1.6rem;
    line-height: 2.6rem;
    text-align: center; }
    .template-title-text__text > * > * {
      color: #2F3542; }

.template-wrapper-2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10rem 0; }
  @media screen and (max-width: 666px) {
    .template-wrapper-2 {
      padding: 5rem 0; } }

.template-wrapper-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0; }
  @media screen and (max-width: 666px) {
    .template-wrapper-4 {
      padding: 5rem 0; } }

.template-title-2 {
  text-align: center; }
  .template-title-2:not(:last-child) {
    margin-bottom: 7rem; }
    @media screen and (max-width: 666px) {
      .template-title-2:not(:last-child) {
        margin-bottom: 3rem; } }

.template-text-2 {
  text-align: center; }

.template-img-2 {
  width: 5rem;
  height: 5rem; }
  .template-img-2:not(:last-child) {
    margin-bottom: 4rem; }
    @media screen and (max-width: 666px) {
      .template-img-2:not(:last-child) {
        margin-bottom: 2rem; } }
  .template-img-2 > * {
    object-fit: contain; }

.template-wrapper-slider {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 666px) {
    .template-wrapper-slider {
      flex-direction: column; } }
  @media screen and (min-width: 667px) {
    .template-wrapper-slider_reverse {
      flex-direction: row-reverse; } }

.template-slider__box {
  position: relative;
  max-width: 75rem;
  width: 100%;
  min-width: 0; }
  @media screen and (min-width: 667px) {
    .template-slider__box {
      margin: 0 11rem 0 15rem;
      padding: 6rem 0 12.4rem 0; } }
  @media screen and (max-width: 666px) {
    .template-slider__box {
      padding: 3rem 0; } }

.template-slider__slide_wp-editor {
  display: flex;
  flex-direction: column; }
  .template-slider__slide_wp-editor > h2 {
    font-family: "RobotoCondensed";
    font-size: 3.6rem;
    line-height: 4.2rem;
    color: #2F3542; }
    .template-slider__slide_wp-editor > h2:not(:last-child) {
      margin-bottom: 3rem; }
      @media screen and (max-width: 666px) {
        .template-slider__slide_wp-editor > h2:not(:last-child) {
          margin-bottom: 1.5rem; } }
  .template-slider__slide_wp-editor > h3 {
    font-family: "RobotoCondensed";
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #2D3436; }
    .template-slider__slide_wp-editor > h3:not(:last-child) {
      margin-bottom: 4rem; }
      @media screen and (max-width: 666px) {
        .template-slider__slide_wp-editor > h3:not(:last-child) {
          margin-bottom: 2rem; } }
  .template-slider__slide_wp-editor > h4 {
    font-family: "RobotoCondensed";
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    color: #57606F; }
    .template-slider__slide_wp-editor > h4:not(:last-child) {
      margin-bottom: 3rem; }
      @media screen and (max-width: 666px) {
        .template-slider__slide_wp-editor > h4:not(:last-child) {
          margin-bottom: 1.5rem; } }
  .template-slider__slide_wp-editor > p {
    font-size: 1.8rem;
    line-height: 2.9rem;
    color: #2F3542; }
    .template-slider__slide_wp-editor > p:not(:last-child) {
      margin-bottom: 3rem; }
      @media screen and (max-width: 666px) {
        .template-slider__slide_wp-editor > p:not(:last-child) {
          margin-bottom: 1.5rem; } }
    .template-slider__slide_wp-editor > p > strong {
      font-weight: 500;
      line-height: 3.2rem; }
    .template-slider__slide_wp-editor > p > a {
      font-size: 1.8rem;
      line-height: 2.1rem;
      text-decoration-line: underline;
      color: #54A0FF; }
  .template-slider__slide_wp-editor > button {
    min-width: 18rem;
    max-width: max-content;
    background-color: #E44A3C;
    border-radius: 5rem;
    text-align: center;
    padding: 2rem;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
    @media screen and (max-width: 666px) {
      .template-slider__slide_wp-editor > button {
        padding: 1rem;
        min-width: 14rem; } }
    .template-slider__slide_wp-editor > button:hover {
      background-color: #2F3542; }
    .template-slider__slide_wp-editor > button > a {
      font-family: "RobotoCondensed";
      font-size: 1.8rem;
      line-height: 2.1rem;
      letter-spacing: 0.05em;
      color: #ffffff; }
      @media screen and (max-width: 666px) {
        .template-slider__slide_wp-editor > button > a {
          font-size: 1.5rem;
          line-height: 2rem; } }
  .template-slider__slide_wp-editor > ul {
    max-width: 60rem;
    width: 100%;
    padding-left: 2rem; }
    .template-slider__slide_wp-editor > ul:not(:last-child) {
      margin-bottom: 5rem; }
    .template-slider__slide_wp-editor > ul > li {
      position: relative;
      font-size: 1.8rem;
      line-height: 2.9rem;
      color: #2F3542; }
      .template-slider__slide_wp-editor > ul > li:not(:last-child) {
        margin-bottom: 3rem; }
        @media screen and (max-width: 666px) {
          .template-slider__slide_wp-editor > ul > li:not(:last-child) {
            margin-bottom: 1.5rem; } }
      .template-slider__slide_wp-editor > ul > li > mark {
        background-color: transparent;
        color: #8395A7; }
      .template-slider__slide_wp-editor > ul > li > a {
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-decoration-line: underline;
        color: #54A0FF; }
      .template-slider__slide_wp-editor > ul > li::before {
        content: "";
        position: absolute;
        top: 1.1rem;
        left: -2rem;
        width: 0.6rem;
        height: 0.6rem;
        background: #E44A3C;
        border-radius: 50%; }
  .template-slider__slide_wp-editor > ol {
    max-width: 60rem;
    width: 100%;
    padding-left: 5rem;
    counter-reset: myCounter; }
    .template-slider__slide_wp-editor > ol:not(:last-child) {
      margin-bottom: 5rem; }
    .template-slider__slide_wp-editor > ol > li {
      position: relative;
      font-size: 1.8rem;
      line-height: 2.9rem;
      color: #2F3542; }
      .template-slider__slide_wp-editor > ol > li:not(:last-child) {
        margin-bottom: 1.6rem; }
      .template-slider__slide_wp-editor > ol > li > mark {
        background-color: transparent;
        color: #8395A7; }
      .template-slider__slide_wp-editor > ol > li > a {
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-decoration-line: underline;
        color: #54A0FF; }
      .template-slider__slide_wp-editor > ol > li::before {
        counter-increment: myCounter;
        content: counter(myCounter);
        color: #ffffff;
        background: #E44A3C;
        display: inline-block;
        text-align: center;
        font-weight: 700;
        min-width: 3.5rem;
        height: 3.5rem;
        font-size: 1.6rem;
        line-height: 3.5rem;
        position: absolute;
        top: 0;
        left: -5rem; }

.template-slider__navigation-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 666px) {
    .template-slider__navigation-buttons {
      display: none; } }

.template-slider__button-prev {
  margin-right: 1.4rem !important; }

.template-slider__button-prev, .template-slider__button-next {
  position: static;
  width: 6.4rem;
  height: 6.4rem;
  margin: 0;
  border-radius: 5rem;
  border: solid 1px #E44A3C;
  background-color: #ffffff;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none; }
  .template-slider__button-prev:hover, .template-slider__button-next:hover {
    background-color: #E44A3C; }
    .template-slider__button-prev:hover::after, .template-slider__button-next:hover::after {
      color: #ffffff; }
  .template-slider__button-prev::after, .template-slider__button-next::after {
    font-size: 1.7rem;
    font-weight: 600;
    color: #E44A3C;
    transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
  .template-slider__button-prev.swiper-button-disabled, .template-slider__button-next.swiper-button-disabled {
    background: #FEFEFE;
    border: 1px solid #F9DAD7;
    backdrop-filter: blur(2rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 5rem; }

.template-slider__pagination {
  display: flex;
  align-items: center;
  left: 0; }
  @media screen and (max-width: 666px) {
    .template-slider__pagination {
      justify-content: center;
      top: auto;
      bottom: 0 !important; } }
  @media screen and (min-width: 667px) {
    .template-slider__pagination {
      width: auto !important;
      bottom: auto !important;
      top: 0; } }
  .template-slider__pagination .swiper-pagination-bullet {
    width: 0.6rem;
    height: 0.6rem; }
    .template-slider__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #E44A3C;
      width: 1rem;
      height: 1rem; }

.template-slider_reverse__box {
  position: relative;
  max-width: 75rem;
  width: 100%;
  min-width: 0; }
  @media screen and (min-width: 667px) {
    .template-slider_reverse__box {
      margin: 0 11rem 0 15rem;
      padding: 6rem 0 12.4rem 0; } }
  @media screen and (max-width: 666px) {
    .template-slider_reverse__box {
      padding: 3rem 0; } }

.template-slider_reverse__slide_wp-editor {
  display: flex;
  flex-direction: column; }
  .template-slider_reverse__slide_wp-editor > h2 {
    font-family: "RobotoCondensed";
    font-size: 3.6rem;
    line-height: 4.2rem;
    color: #2F3542; }
    .template-slider_reverse__slide_wp-editor > h2:not(:last-child) {
      margin-bottom: 3rem; }
      @media screen and (max-width: 666px) {
        .template-slider_reverse__slide_wp-editor > h2:not(:last-child) {
          margin-bottom: 1.5rem; } }
  .template-slider_reverse__slide_wp-editor > h3 {
    font-family: "RobotoCondensed";
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #2D3436; }
    .template-slider_reverse__slide_wp-editor > h3:not(:last-child) {
      margin-bottom: 4rem; }
      @media screen and (max-width: 666px) {
        .template-slider_reverse__slide_wp-editor > h3:not(:last-child) {
          margin-bottom: 2rem; } }
  .template-slider_reverse__slide_wp-editor > h4 {
    font-family: "RobotoCondensed";
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    color: #57606F; }
    .template-slider_reverse__slide_wp-editor > h4:not(:last-child) {
      margin-bottom: 3rem; }
      @media screen and (max-width: 666px) {
        .template-slider_reverse__slide_wp-editor > h4:not(:last-child) {
          margin-bottom: 1.5rem; } }
  .template-slider_reverse__slide_wp-editor > p {
    font-size: 1.8rem;
    line-height: 2.9rem;
    color: #2F3542; }
    .template-slider_reverse__slide_wp-editor > p:not(:last-child) {
      margin-bottom: 3rem; }
      @media screen and (max-width: 666px) {
        .template-slider_reverse__slide_wp-editor > p:not(:last-child) {
          margin-bottom: 1.5rem; } }
    .template-slider_reverse__slide_wp-editor > p > strong {
      font-weight: 500;
      line-height: 3.2rem; }
    .template-slider_reverse__slide_wp-editor > p > a {
      font-size: 1.8rem;
      line-height: 2.1rem;
      text-decoration-line: underline;
      color: #54A0FF; }
  .template-slider_reverse__slide_wp-editor > button {
    min-width: 18rem;
    max-width: max-content;
    background-color: #E44A3C;
    border-radius: 5rem;
    text-align: center;
    padding: 2rem;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
    @media screen and (max-width: 666px) {
      .template-slider_reverse__slide_wp-editor > button {
        padding: 1rem;
        min-width: 14rem; } }
    .template-slider_reverse__slide_wp-editor > button:hover {
      background-color: #2F3542; }
    .template-slider_reverse__slide_wp-editor > button > a {
      font-family: "RobotoCondensed";
      font-size: 1.8rem;
      line-height: 2.1rem;
      letter-spacing: 0.05em;
      color: #ffffff; }
      @media screen and (max-width: 666px) {
        .template-slider_reverse__slide_wp-editor > button > a {
          font-size: 1.5rem;
          line-height: 2rem; } }
  .template-slider_reverse__slide_wp-editor > ul, .template-slider_reverse__slide_wp-editor ol {
    max-width: 60rem;
    width: 100%;
    padding-left: 2rem; }
    .template-slider_reverse__slide_wp-editor > ul:not(:last-child), .template-slider_reverse__slide_wp-editor ol:not(:last-child) {
      margin-bottom: 5rem; }
    .template-slider_reverse__slide_wp-editor > ul > li, .template-slider_reverse__slide_wp-editor ol > li {
      position: relative;
      font-size: 1.8rem;
      line-height: 2.9rem;
      color: #2F3542; }
      .template-slider_reverse__slide_wp-editor > ul > li:not(:last-child), .template-slider_reverse__slide_wp-editor ol > li:not(:last-child) {
        margin-bottom: 3rem; }
        @media screen and (max-width: 666px) {
          .template-slider_reverse__slide_wp-editor > ul > li:not(:last-child), .template-slider_reverse__slide_wp-editor ol > li:not(:last-child) {
            margin-bottom: 1.5rem; } }
      .template-slider_reverse__slide_wp-editor > ul > li > mark, .template-slider_reverse__slide_wp-editor ol > li > mark {
        background-color: transparent;
        color: #8395A7; }
      .template-slider_reverse__slide_wp-editor > ul > li > a, .template-slider_reverse__slide_wp-editor ol > li > a {
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-decoration-line: underline;
        color: #54A0FF; }
      .template-slider_reverse__slide_wp-editor > ul > li::before, .template-slider_reverse__slide_wp-editor ol > li::before {
        content: "";
        position: absolute;
        top: 1.1rem;
        left: -2rem;
        width: 0.6rem;
        height: 0.6rem;
        background: #E44A3C;
        border-radius: 50%; }

.template-slider_reverse__navigation-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 666px) {
    .template-slider_reverse__navigation-buttons {
      display: none; } }

.template-slider_reverse__button-prev {
  margin-right: 1.4rem !important; }

.template-slider_reverse__button-prev, .template-slider_reverse__button-next {
  position: static;
  width: 6.4rem;
  height: 6.4rem;
  margin: 0;
  border-radius: 5rem;
  border: solid 1px #E44A3C;
  background-color: #ffffff;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none; }
  .template-slider_reverse__button-prev:hover, .template-slider_reverse__button-next:hover {
    background-color: #E44A3C; }
    .template-slider_reverse__button-prev:hover::after, .template-slider_reverse__button-next:hover::after {
      color: #ffffff; }
  .template-slider_reverse__button-prev::after, .template-slider_reverse__button-next::after {
    font-size: 1.7rem;
    font-weight: 600;
    color: #E44A3C;
    transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
  .template-slider_reverse__button-prev.swiper-button-disabled, .template-slider_reverse__button-next.swiper-button-disabled {
    background: #FEFEFE;
    border: 1px solid #F9DAD7;
    backdrop-filter: blur(2rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 5rem; }

.template-slider_reverse__pagination {
  display: flex;
  align-items: center;
  left: 0; }
  @media screen and (max-width: 666px) {
    .template-slider_reverse__pagination {
      justify-content: center;
      top: auto;
      bottom: 0 !important; } }
  @media screen and (min-width: 667px) {
    .template-slider_reverse__pagination {
      width: auto !important;
      bottom: auto !important;
      top: 0; } }
  .template-slider_reverse__pagination .swiper-pagination-bullet {
    width: 0.6rem;
    height: 0.6rem; }
    .template-slider_reverse__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #E44A3C;
      width: 1rem;
      height: 1rem; }

.template-img-slider {
  position: relative;
  max-width: 83rem;
  width: 100%; }
  @media screen and (min-width: 667px) {
    .template-img-slider {
      height: 1px;
      max-height: 100.8rem;
      min-height: 100.8rem; } }
  @media screen and (max-width: 666px) {
    .template-img-slider {
      margin-top: 2.5rem; } }
  @media screen and (min-width: 667px) {
    .template-img-slider_right {
      margin-right: -8rem; } }
  .template-img-slider_right .template-img-slider__text {
    left: -3.5rem; }
  @media screen and (min-width: 667px) {
    .template-img-slider_left {
      margin-left: -8rem; } }
  .template-img-slider_left .template-img-slider__text {
    right: -3.5rem; }
  @media screen and (max-width: 666px) {
    .template-img-slider > * {
      object-fit: contain; } }
  .template-img-slider__text {
    position: absolute;
    bottom: 0;
    display: inline-block;
    writing-mode: tb;
    transform: rotate(-180deg); }
    @media screen and (max-width: 666px) {
      .template-img-slider__text {
        display: none; } }
    .template-img-slider__text > * {
      font-size: 1.8rem;
      line-height: 2.1rem;
      text-transform: uppercase;
      color: #929A9C; }

.template-list {
  display: flex;
  justify-content: center;
  max-width: 146rem;
  width: 100%; }
  .template-list_2 {
    justify-content: space-between; }
  @media screen and (max-width: 479px) {
    .template-list_3 {
      flex-direction: column;
      align-items: center; } }
  .template-list_4 {
    flex-wrap: wrap; }
  .template-list_6 {
    flex-wrap: wrap; }

.template-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .template-item_2 {
    max-width: 60rem;
    width: 100%;
    flex: 0 1 50%; }
    .template-item_2:not(:last-child) {
      margin-right: 26rem; }
      @media screen and (max-width: 666px) {
        .template-item_2:not(:last-child) {
          margin-right: 5rem; } }
      .template-item_2:not(:last-child)::after {
        content: "";
        position: absolute;
        top: 0;
        right: -13rem;
        width: 1px;
        height: 100%;
        background-color: #D8E2ED; }
        @media screen and (max-width: 666px) {
          .template-item_2:not(:last-child)::after {
            right: -2.5rem; } }
  .template-item_3 {
    max-width: 41rem;
    width: 100%; }
    @media screen and (max-width: 479px) {
      .template-item_3 {
        max-width: 30rem;
        width: 100%; } }
    .template-item_3:not(:last-child) {
      margin-right: 11rem; }
      .template-item_3:not(:last-child)::before {
        content: "";
        position: absolute;
        top: 0;
        right: -5.5rem;
        width: 1px;
        height: 100%;
        background: #D8E2ED; }
        @media screen and (max-width: 666px) {
          .template-item_3:not(:last-child)::before {
            right: -1rem; } }
        @media screen and (max-width: 479px) {
          .template-item_3:not(:last-child)::before {
            top: auto;
            right: auto;
            bottom: -2rem;
            height: 1px;
            width: 100%; } }
      @media screen and (max-width: 666px) {
        .template-item_3:not(:last-child) {
          margin-right: 2rem; } }
      @media screen and (max-width: 479px) {
        .template-item_3:not(:last-child) {
          margin-bottom: 4rem;
          margin-right: 0; } }
  .template-item_4 {
    flex: 0 1 50%; }
    .template-item_4:nth-child(1) {
      border-right: #D8E2ED solid 1px;
      border-bottom: #D8E2ED solid 1px;
      padding-bottom: 8rem; }
    .template-item_4:nth-child(2) {
      border-bottom: #D8E2ED solid 1px;
      padding-bottom: 8rem; }
    .template-item_4:nth-child(3) {
      border-right: #D8E2ED solid 1px;
      padding-top: 8rem; }
    .template-item_4:nth-child(4) {
      margin-top: 8rem; }
  .template-item_6 {
    flex: 0 1 33.33%; }
    @media screen and (max-width: 479px) {
      .template-item_6 {
        flex: 0 1 50%; } }
    .template-item_6:nth-child(1) {
      border-right: #D8E2ED solid 1px;
      border-bottom: #D8E2ED solid 1px;
      padding-bottom: 6rem; }
    .template-item_6:nth-child(2) {
      border-right: #D8E2ED solid 1px;
      border-bottom: #D8E2ED solid 1px;
      padding-bottom: 6rem; }
      @media screen and (max-width: 479px) {
        .template-item_6:nth-child(2) {
          border-right: none; } }
    .template-item_6:nth-child(3) {
      border-bottom: #D8E2ED solid 1px;
      padding-bottom: 6rem; }
      @media screen and (max-width: 479px) {
        .template-item_6:nth-child(3) {
          border-right: #D8E2ED solid 1px;
          padding-top: 6rem; } }
    .template-item_6:nth-child(4) {
      padding-top: 6rem;
      border-right: #D8E2ED solid 1px; }
      @media screen and (max-width: 479px) {
        .template-item_6:nth-child(4) {
          border-right: none;
          border-bottom: #D8E2ED solid 1px;
          padding-bottom: 6rem; } }
    .template-item_6:nth-child(5) {
      padding-top: 6rem;
      border-right: #D8E2ED solid 1px; }
    .template-item_6:nth-child(6) {
      padding-top: 6rem; }

/* ============================ template END ================================================================= */
@font-face {
  font-family: "RobotoCondensed";
  src: url("/static/fonts/RobotoCondensed-Regular.woff") format("woff"), url("/static/fonts/RobotoCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("/static/fonts/RobotoCondensed-Medium.woff") format("woff"), url("/static/fonts/RobotoCondensed-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("/static/fonts/RobotoCondensed-Bold.woff") format("woff"), url("/static/fonts/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Roboto";
  src: url("/static/fonts/Roboto-Regular.woff") format("woff"), url("/static/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Roboto";
  src: url("/static/fonts/Roboto-Medium.woff") format("woff"), url("/static/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }
