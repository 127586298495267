@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ wood START ================================================================= */
.choose-wood{
   padding-bottom: 10rem;
   @media screen and (max-width: 666px) {
      padding-bottom: 5rem;
   }
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}
/* ============================ wood END ================================================================= */