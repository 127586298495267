@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";


/* ============================ ornamental-gates START ================================================================= */
.austin-ornamental-gates{
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}
/* ============================ ornamental-gates END ================================================================= */