@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ sundance START ================================================================= */
.gallery-sundance {
   &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 146rem;
      width: 100%;
      margin: 0 auto;
      padding: 7rem 0 10rem;
      @media screen and (max-width: 666px) {
         padding: 3rem 0;
      }
   }
   &__content {
      width: 100%;
   }
   &__btn-box {
      display: inline-flex;
      border-bottom: solid 1px #DFE6E9;
      &:not(:last-child) {
         margin-bottom: 7rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
   }
   &__btn {
      position: relative;
      padding-bottom: 1rem;
      &:not(:last-child) {
         margin-right: 6rem;
         @media screen and (max-width: 666px) {
            margin-right: 2rem;
         }
      }
      &.active {
         cursor: auto;
         &::after {
            content: "";
            position: absolute;
            bottom: -0.1rem;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $accent;
         }
         & > * {
            color: $accent;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-weight: 500;
         font-size: 1.8rem;
         line-height: 2.1rem;
         text-align: center;
         color: #C8CCCD;
      }
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -4rem 0 0 -4rem;
      @media screen and (max-width: 666px) {
         margin: -2rem 0 0 -2rem;
      }
      @media screen and (max-width: 479px) {
         flex-direction: column;
         align-items: center;
         margin: 0;
      }
   }
   &__item {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3 - 4rem);
      margin: 4rem 0 0 4rem;
      @media screen and (max-width: 666px) {
         margin: 2rem 0 0 2rem;
         width: calc(100% / 2 - 2rem);
      }
      @media screen and (max-width: 479px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
   }
   &__img {
      min-height: 25.8rem;
      max-height: 25.8rem;
      height: 1px;
   }

   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}
/* ============================ sundance END ================================================================= */