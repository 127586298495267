@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ warranty START ================================================================= */
.warranty {
   &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 86rem;
      width: 100%;
      padding: 2.4rem 0 10rem;
      margin: 0 auto;
      @media screen and (max-width: 666px) {
         padding: 2rem 0 5rem;
      }
   }
   &__title {
      @extend %title-h2;
      text-align: center;
      &:not(:last-child) {
         margin-bottom: 6rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
   }
   &__text {
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $title-color;
         & > a {
            color: $link-color;
            text-decoration: underline;
         }
         &:not(:last-child) {
            margin-bottom: 1.6rem;
         }
      }
      & > ol {
         padding-left: 5rem;
         &:not(:last-child) {
            margin-bottom: 5rem;
         }
         counter-reset: myCounter;
         & > li {
            position: relative;
            &:not(:last-child) {
               margin-bottom: 1.6rem;
            }
            font-size: 1.6rem;
            line-height: 2.6rem;
            color: $title-color;
            &::before {
               counter-increment: myCounter;
               content: counter(myCounter);
               color: $white-text-color;
               background: $accent;
               display: inline-block;
               text-align: center;
               font-weight: 700;
               min-width: 3.5rem;
               height: 3.5rem;
               font-size: 1.6rem;
               line-height: 3.5rem;
               position: absolute;
               top: 0;
               left: -5rem;
            }
         }
      }
   }
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}
/* ============================ warranty END ================================================================= */