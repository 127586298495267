@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ temporary START ================================================================= */
.work-temporary {
   padding-bottom: 10rem;
   @media screen and (max-width: 666px) {
      padding-bottom: 5rem;
   }
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}
/* ============================ temporary END ================================================================= */