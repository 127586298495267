@import '../app/variable.scss';
/* ============================ footer START ================================================================= */
.footer {
   margin-top: 10rem;
   @media screen and (max-width: 666px) {
      margin-top: 3rem;
   }
   &__body {
      padding: 14.4rem 0;
      background: $bg-color;
      @media screen and (max-width: 666px) {
         padding: 3rem 0;
      }
   }
   &__container {
      //position: relative;
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
      @media screen and (min-width: 667px) {
         height: 23rem;
      }
   }
   &__bottom {
      padding: 3rem 0 6rem;
   }
   &__icon {
      height: 6rem;
      width: 32rem;
      @media screen and (max-width: 666px) {
         width: 23.7rem;
      }
      & > * {
         height: 6rem;
         width: 32rem;
         @media screen and (max-width: 666px) {
            width: 23.7rem;
         }
      }
   }
}

.body-footer {
   &__wrapper {
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
      @media screen and (min-width: 667px) {
         width: 50%;
         margin-right: 2rem;
         flex-shrink: 0;
      }
      @media screen and (min-width: 667px) and (max-width: 700px) {
         width: 53%
      }
   }
   &__logo {
      position: relative;
      margin-bottom: 8.2rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
      &::after {
         content: "";
         position: absolute;
         bottom: -3rem;
         left: 0;
         width: 10rem;
         height: 2px;
         background-color: $accent;
         @media screen and (max-width: 666px) {
            bottom: 0;
         }
      }
   }
   &__icon {
      width: 4rem;
      height: 4rem;
      margin-right: 1rem;
      flex-shrink: 0;
      & > * {
         width: 4rem;
         height: 4rem;
      }
   }
   &__list {
      display: flex;
      @media screen and (min-width: 667px) {
         margin: -3rem 0 0 -3rem;
      }
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__item {
      display: flex;
      &:first-child {
         @media screen and (min-width: 667px) {
            flex: 0 1 30rem;
         }
      }
      & > * {
         display: flex;
         font-style: normal;
         font-size: 1.8rem;
         line-height: 2.9rem;
         color: $secondary;
         & > * {
            & > * {
               display: block;
            }

         }
      }
      &:not(:last-child) {
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
      @media screen and (min-width: 667px) {
         margin: 3rem 0 0 3rem;
      }
   }
   &__map {
      height: 50rem;
      max-width: 98rem;
      width: 100%;
      @media screen and (min-width: 667px) {
         height: 63rem;
         margin-right: -8rem;
      }
      & > * {
         width: 100%;
         height: 100%;
      }
   }
}

.footer-bottom {
   &__wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      @media screen and (max-width: 666px) {
         align-items: center;
         flex-direction: column;
      }
   }
   &__title {
      margin-bottom: 2.4rem;
      & > * {
         font-size: 1.8rem;
         line-height: 2.9rem;
         color: $title-color;
      }
   }
   &__list {
      max-width: 70rem;
      width: 100%;
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         flex-wrap: wrap;
         margin: -2rem 0 0 -2rem;
      }
      @media screen and (min-width: 667px) {
         margin: -1rem 0 0 -1rem;
         justify-content: space-between;
      }
      @media screen and (min-width: 667px) and (max-width: 750px) {
         max-width: 60rem;
      }
   }
   &__item {
      @media screen and (max-width: 666px) {
         margin: 2rem 0 0 2rem;
         width: calc(100% / 2 - 2rem);
         display: flex;
         justify-content: center;
      }
      @media screen and (min-width: 667px) {
         margin: 1rem 0 0 1rem;
      }
   }
   &__img {
      &_1 {
         max-width: 10rem;
         width: 100%;
         max-height: 3.8rem;
         min-height: 3.8rem;
         height: 1px;
      }
      &_2 {
         max-width: 10.8rem;
         width: 100%;
         max-height: 4rem;
         min-height: 4rem;
         height: 1px;
      }
      &_3 {
         max-width: 7rem;
         width: 100%;
         max-height: 7.5rem;
         min-height: 7.5rem;
         height: 1px;
      }
      &_4 {
         max-width: 8rem;
         width: 100%;
         max-height: 7.6rem;
         min-height: 7.6rem;
         height: 1px;
      }
      &_5 {
         max-width: 15rem;
         width: 100%;
         max-height: 6rem;
         min-height: 6rem;
         height: 1px;
      }
   }
   &__text {
      @media screen and(min-width: 750px) {
         margin-left: -10rem;
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $title-color;
         opacity: 0.5;
         @media screen and (max-width: 666px) {
            text-align: center;
         }
      }
   }
}

.social {
   display: flex;
   align-items: center;
   &.social_blog-post {
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
      @media screen and (min-width: 667px) {
         margin-right: 10rem;
         display: inline-block;
      }
   }
   &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.6rem;
      height: 3.6rem;
      background: $bg-color;
      border-radius: 50%;
      transition: background-color .25s $time-distribution-function;
      &_blog-post {
         width: 5rem;
         height: 5rem;
      }
      &:hover {
         background: $title-color;

      }
   }
   &__icon {
      width: 2.2rem;
      height: 2.2rem;
      & > * {
         width: 2.2rem;
         height: 2.2rem;
      }
   }
   svg {
      path {
         fill: $secondary;
         stroke: $secondary;
         stroke-width: 0.2px;
         transition: fill .25s $time-distribution-function, stroke .25s $time-distribution-function;
      }
      circle {
         fill: $bg-color;
         transition: fill .25s $time-distribution-function;
      }
   }
   &__item {
      &:not(:last-child) {
         margin-right: 1.5rem;
      }
      &_blog-post {
         &:not(:last-child) {
            @media screen and (min-width: 667px) {
               margin-bottom: 3rem;

            }
         }
      }
      &:hover {
         svg {
            path {
               fill: $white-text-color;
               stroke: $white-text-color;
               stroke-width: 0.2px
            }
            circle {
               fill: $title-color;
            }
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 1.8rem;
         line-height: 2.1rem;
         letter-spacing: 0.05em;
         color: $title-color;
      }
   }
}

.bottom-item {
   @media screen and (max-width: 666px) {
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
   }
}
/* ============================ footer END ================================================================= */
