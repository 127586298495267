@import '../../app/variable.scss';
// щоб стилі кнопки примінялись імпортуємо в scss файл стилі кнопки[@import "../components/button/button.scss";]
.button {
   border: none;
   max-width: 22.1rem;
   width: 100%;
   background-color: $accent;
   border-radius: 5rem;
   text-align: center;
   padding: 2rem 5rem;
   transition: background-color .25s $time-distribution-function;
   &:hover {
      background-color: $title-color;
   }

   & > * {
      font-family: $family_RobotoCondensed;
      font-size: 1.8rem;
      line-height: 2.1rem;
      letter-spacing: 0.05em;
      color: $white-text-color;
      @media screen and (max-width: 666px) {
         font-size: 1.5rem;
         line-height: 2rem;
      }
   }
}

.link {
   max-width: 22.1rem;
   width: 100%;
   background-color: $accent;
   border-radius: 5rem;
   text-align: center;
   padding: 2rem;
   @media screen and (max-width: 666px) {
      padding: 1rem;
   }
   & > * {
      font-family: $family_RobotoCondensed;
      font-size: 1.8rem;
      line-height: 2.1rem;
      letter-spacing: 0.05em;
      color: $white-text-color;
      @media screen and (max-width: 666px) {
         font-size: 1.5rem;
         line-height: 2rem;
      }
   }
}
//@@include( 'develop/components/button/button.html', {
//"title": "вход в Личный кабинет",
//"mod": "test__btn",
//"isButton": "button",
//"href": "login.html",
//"type": "button",
//"img": "@zz/time.svg",
//"alt": "",
//"dataName":"",
//"dataValue":""
//})

