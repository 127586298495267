$primary-text-color: #8395A7;
$secondary: #57606F;
$white-text-color: #ffffff;
$accent: #E44A3C;
$title-color: #2F3542;
$bg-color: #F7F9FB;
$gray-text: #636E72;
$dark-color: #000000;
$link-color: #54A0FF;

$time-distribution-function: cubic-bezier(0.4, 0, 0.2, 1);

$family_Roboto: 'Roboto';
$family_RobotoCondensed: 'RobotoCondensed';

