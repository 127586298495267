@import '../app/variable.scss';
/* ============================ header START ================================================================= */
.header {
   box-shadow: 0 .1rem .8rem #EFF3F7;
   height: 10rem;
   @media screen and (max-width: 666px) {
      display: flex;
      align-items: center;
      padding-right: 1.5rem;
      height: auto;
   }
   &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__logo {
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
   }
   &__icon {
      height: 6rem;
      width: 32rem;
      @media screen and (max-width: 666px) {
         width: 23.7rem;
      }
      & > * {
         display: inline-block;
         height: 6rem;
         width: 32rem;
         @media screen and (max-width: 666px) {
            width: 23.7rem;
         }
      }
   }
   &__btn.button {
      max-width: none;
      width: auto;
      padding: 2rem 5rem;
      @media screen and (max-width: 666px) {
         min-width: 14rem;
         padding: 1rem;
         & > * {
            font-size: 1.5rem;
            line-height: 2rem;
         }
      }
   }
}

.menu-header {
   @media screen and (max-width: 666px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      padding-right: 6.5rem;
      z-index: 2;
      transform: translateX(-100%);
      transition: transform .25s $time-distribution-function;
      overflow: auto;
      &.open {
         transform: translateX(0);
      }
   }
   &__burger {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      &.open {
         display: none;
      }
      @media screen and (min-width: 667px) {
         display: none;
      }
      & > * {
         width: 3rem;
         height: 3rem;
      }
   }
   &__wrapper {
      @media screen and (max-width: 666px) {
         padding: 5rem 3rem 0;
         height: 100%;
         background-color: $white-text-color;
         text-align: center;
         position: relative;
         overflow: hidden;
      }
   }
   &__list {
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         flex-direction: column;
         margin-bottom: 2rem;
      }
   }
   &__item {
      &:not(:last-child) {
         @media screen and (min-width: 667px) and (max-width: 1023px) {
            margin-right: 2rem;
         }
         @media screen and (min-width: 1024px) {
            margin-right: 4rem;
         }
         @media screen and (max-width: 666px) {
            margin-bottom: 1rem;
         }
      }
      &:hover {
         & > a {
            @media screen and (min-width: 667px) {
               color: $accent;
            }
         }
         & .sub-menu__wrapper {
            @media screen and (min-width: 667px) {
               display: block;
            }
         }
      }
      & > a {
         font-family: $family_RobotoCondensed;
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $title-color;
         transition: color .25s $time-distribution-function;
         padding: 1rem;
         @media screen and (min-width: 667px) {
            padding: 2rem 1rem;
         }
         @media screen and (max-width: 666px) {
            width: 100%;
         }
      }
      &.sub {
         @media screen and (max-width: 666px) {
            & > a {
               position: relative;
               &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  right: 0;
                  width: 1rem;
                  height: 1rem;
                  transform: translate(0, -50%);
                  background: url("app/blocks/develop/header/img/sprite/arrow.svg") no-repeat center;
                  background-size: cover;
               }
            }
         }
      }
      @media screen and (max-width: 666px) {
         width: 100%;
         text-align: left;
      }
      @media screen and (min-width: 667px) {
         position: relative;
      }
   }
   &__close {
      position: absolute;
      top: 1.6rem;
      right: 1.5rem;
      cursor: pointer;
      transition: opacity 1.5s $time-distribution-function;
      @media screen and (min-width: 667px) {
         display: none;
      }
   }
   &__icon {
      width: 3.3rem;
      height: 3.3rem;
      & > * {
         width: 3.3rem;
         height: 3.3rem;
      }
   }
}

.sub-menu {
   & > li {
      &:not(:last-child) {
         margin-bottom: 1rem;
      }
      & > a {
         display: flex;
         align-items: center;
         padding: 1rem;
         &:hover {
            & > * {
               color: $accent;
            }
            & > svg path {
               fill: $accent;
            }
         }
         & > * {
            font-family: $family_RobotoCondensed;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: $title-color;
            transition: color .25s $time-distribution-function;
         }
         & > svg {
            width: 1rem;
            height: 1rem;
            flex-shrink: 0;
            margin-left: 1rem;
            & path {
               transition: fill .25s $time-distribution-function;
            }
            @media screen and (max-width: 666px) {
               display: none;
            }
         }
      }
   }
   &__wrapper {
      position: absolute;
      left: -2rem;
      top: 6rem;
      background: $white-text-color;
      padding: 2rem;
      width: max-content;
      z-index: 2;
      @media screen and (max-width: 666px) {
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         width: 100%;
         transform: translateX(100%);
         padding: 5rem 3rem;
         transition: transform .25s $time-distribution-function;
         &.open {
            transform: translateX(0);
         }
      }
      @media screen and (min-width: 667px) {
         display: none;
         border: 1px solid #DFE6E9;
         backdrop-filter: blur(2rem);
         border-radius: 0.4rem;
      }
   }
   &__btn-back {
      margin-bottom: 3rem;
      @media screen and (min-width: 667px) {
         display: none;
      }
      @media screen and (max-width: 666px) {
         cursor: pointer;
         position: relative;
         &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: -1rem;
            width: 1rem;
            height: 1rem;
            transform: translate(0, -50%) rotate(-180deg);
            background: url("app/blocks/develop/header/img/sprite/arrow.svg") no-repeat center;
            background-size: cover;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $title-color;
         transition: color .25s $time-distribution-function;
         padding: 1rem;
      }
   }
}

.shadow {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 2;
   background: #000000a6;
   opacity: 1;
   transition: opacity .25s $time-distribution-function;
   &.is-hidden {
      opacity: 0;
      pointer-events: none;
   }
}

.form-header {
   width: 100%;
   display: flex;
   flex-direction: column;
   &__container {
      padding: 0;
      @media screen and (min-width: 667px) {
         position: absolute;
         top: 0;
         right: 0;
         height: 100vh;
         width: 50vw;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
   &.is-hidden {
      display: none;
   }
   &__inner {
      padding: 5rem 2rem;
      @media screen and (min-width: 667px) {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: 100%;
      }
   }
   &__wrapper {
      display: flex;
      flex-direction: column;
      max-width: 56rem;
      width: 100%;
      margin: 0 auto;
   }
   &__title {
      position: relative;
      margin-bottom: 5rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 2.5rem;
      }
      &::after {
         content: "";
         position: absolute;
         bottom: -2.4rem;
         left: 50%;
         transform: translate(-50%, 0);
         width: 10rem;
         height: 2px;
         background-color: $accent;
         @media screen and (max-width: 666px) {
            bottom: -1.2rem;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 3.6rem;
         line-height: 4.2rem;
         text-align: center;
         color: $title-color;
         @media screen and (max-width: 666px) {
            //font-size: 4rem;
            //line-height: 5rem;
         }
      }
   }
   &__text {
      max-width: 64rem;
      width: 100%;
      margin-bottom: 5rem;
      align-self: center;
      @media screen and (max-width: 666px) {
         margin-bottom: 2.5rem;
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         text-align: center;
         color: $primary-text-color;
      }
   }
   &__textarea {
      resize: none;
      padding: 2.4rem;
      width: 100%;
      height: 16.8rem;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: $title-color;
      background-color: $bg-color;
      outline: none;
      @media screen and (max-width: 666px) {
         padding: 1rem;
         height: 13rem;
      }
      &::placeholder {
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $primary-text-color;
      }
   }
   &__list {
      display: flex;
      @media screen and (max-width: 666px) {
         margin-bottom: 1.5rem;
         flex-direction: column;
      }
      @media screen and (min-width: 667px) {
         flex-wrap: wrap;
         margin: -2.4rem 0 2.4rem -1.6rem;
      }
   }
   &__item {
      position: relative;
      & input {
         font-size: 1.8rem;
         line-height: 2.1rem;
         width: 100%;
         height: 7.2rem;
         padding: 2.4rem 1.2rem 2.4rem 6.2rem;
         color: $title-color;
         background: $bg-color;
         outline: none;
         @media screen and (max-width: 666px) {
            height: 5rem;
            padding: 1rem 1rem 1rem 3rem;
         }
         &::placeholder {
            font-size: 1.8rem;
            line-height: 2.1rem;
            color: $primary-text-color;
         }
      }
      &.error {
         & .form-header__icon ~ span {
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.4rem;
            color: $accent;
            opacity: 1;
         }
      }
      &:nth-child(1) {
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
            width: calc(100% / 2 - 1.6rem);
         }
      }
      &:nth-child(2) {
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
            width: calc(100% / 2 - 1.6rem);
         }
      }
      &:nth-child(3) {
         width: 100%;
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
         }
      }
      &:nth-child(4) {
         width: 100%;
         @media screen and (min-width: 667px) {
            margin: 2.4rem 0 0 1.6rem;
         }
      }
   }
   &__btn {
      &.button {
         min-width: 21.5rem;
         max-width: none;
         width: auto;
         flex: 0 1 auto;
         align-self: end;
         @media screen and (max-width: 666px) {
            min-width: 14rem;
            max-width: none;
            width: auto;
            padding: 1rem 2rem;
            align-self: center;
         }
      }
      &_success.button {
         align-self: center;
      }
   }
   &__icon {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translate(0, -50%);
      width: 1.8rem;
      height: 1.8rem;
      @media screen and (max-width: 666px) {
         width: 1.5rem;
         height: 1.5rem;
         left: 1rem;
      }
      & > * {
         width: 1.8rem;
         height: 1.8rem;
         @media screen and (max-width: 666px) {
            width: 1.5rem;
            height: 1.5rem;
         }
      }
      & ~ span {
         position: absolute;
         left: 0;
         bottom: -1.7rem;
         opacity: 0;
      }
   }
}

.success-header {
   display: flex;
   flex-direction: column;
   align-items: center;
   &[data-hidden="is-hidden"] {
      display: none;
   }
}
/* ============================ header END ================================================================= */
