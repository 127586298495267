@import "variable.scss";
@import "placeholder.scss";
html {
   font-size: 10px;
   @media screen and (min-width: 667px) {
      font-size: 5px;
   }
   @media screen and (min-width: 992px) {
      font-size: 6px;
   }
   @media screen and (min-width: 1024px) {
      font-size: 7px;
   }
   @media screen and (min-width: 1280px) {
      font-size: 8px;
   }
   @media screen and (min-width: 1366px) {
      font-size: 9px;
   }
   @media screen and (min-width: 1680px) {
      font-size: 10px;
   }
}
* {
   padding: 0;
   margin: 0;
   border: 0;
}
*,
*:before,
*:after {
   box-sizing: border-box;
}
:focus,
:active {
   // outline: none;
}
a:focus,
a:active {
   // outline: none;
}
html,
body {
   height: 100%;
}
body {
   margin: 0;
   padding: 0;
   color: $primary-text-color;
   font-family: $family_Roboto, sans-serif;
   //text-rendering: optimizeLegibility;
   -ms-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   &.no-scroll {
      overflow: hidden;
   }
}
input,
button,
textarea,
optgroup,
select, {
   font-family: $family_Roboto;
   font-size: inherit;
}
button {
   cursor: pointer;
   color: inherit;
   background-color: inherit;
}
picture {
   display: block;
}
p {
   margin: 0;
   padding: 0;
}
a {
   cursor: pointer;
   display: inline-block;
   color: inherit;
}
a:link,
a:visited {
   text-decoration: none;
}
a:hover {
   text-decoration: none;
}
ol,
ul,
li {
   list-style: none;
   margin: 0;
   padding: 0;
}
img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
   //vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
   font-weight: inherit;
   font-size: inherit;
   padding: 0;
   margin: 0;
}
blockquote {
   margin: 0;
   padding: 0;
}
//прибрати стрілки для input type="number"
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
/* Firefox */
input[type=number] {
   -moz-appearance: textfield;
}
.app {
   overflow: hidden;
   display: flex;
   flex-direction: column;
   min-height: 100%;
}
.visually-hidden {
   position: absolute;
   width: 1px;
   height: 1px;
   margin: -1px;
   border: 0;
   padding: 0;
   clip: rect(0 0 0 0);
   overflow: hidden;
}
.main {
   flex: 1 1 auto;
}
[class*="__container"] {
   //розмір контейнера 1760px +30px для падінгів = 1790px
   max-width: 179rem;
   width: 100%;
   margin: 0 auto;
   padding: 0 1.5rem;
}

.popup {
   &__header {
      @media screen and (min-width: 667px) {
         position: absolute;
         width: 50vw;
         height: 100vh;
         right: 0;
      }
      & .fancybox-slide--html,
      & .fancybox-close-small {
         @media screen and (min-width: 667px) {
            top: 50%;
            left: -3.2rem;
            width: 6.4rem;
            height: 6.4rem;
            background-color: rgba(200, 214, 229, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(2rem);
            border-radius: 5rem;
         }
      }
      & .fancybox-button {
         @media screen and (min-width: 667px) {
            &::before {
               content: "";
               position: absolute;
               top: 2rem;
               left: 3rem;
               height: 2.2rem;
               width: 1px;
               background: $white-text-color;
               transform: rotate(45deg);
            }
            &::after {
               content: "";
               position: absolute;
               top: 2rem;
               left: 3rem;
               height: 2.2rem;
               width: 1px;
               background: $white-text-color;
               transform: rotate(-45deg);
            }
         }
      }
      & .fancybox-button svg {
         @media screen and (min-width: 667px) {
            display: none;
         }
      }
      &.fancybox-content {
         @media screen and (min-width: 667px) {
            overflow: visible;
         }
         @media screen and (max-width: 666px) {
            padding: 0;
         }
      }
   }
   &__gallery {
      max-width: 116rem;
      width: 100%;
      padding: 0;
      @media screen and (max-width: 666px) {
         padding: 1rem;
      }
      background-color: transparent;
      &.fancybox-content {
         overflow: visible;
         @media screen and (max-width: 666px) {
            padding: 0;
         }
      }
      &_img {
         height: 63.7rem;
         margin-bottom: 2rem;
         @media screen and (max-width: 666px) {
            height: 30rem;
         }
      }
      &.popup__gallery_content {
         padding: 3rem;
         max-width: none;
         display: flex;
         flex-direction: column;
         align-items: center;
         @media screen and (max-width: 666px) {
            padding: 1rem;
         }
         & > * {
            max-width: 86rem;
            width: 100%;
         }
      }
      & .fancybox-button {
         top: -4rem;
         @media screen and (min-width: 667px) {
            width: 4.4rem;
            height: 4.4rem;
            right: -5.5rem;
            &::before {
               content: "";
               position: absolute;
               transform: rotate(45deg) translate(-50%, -50%);
               top: 38%;
               left: 25%;
               height: 3.2rem;
               width: 0.2rem;
               background: $white-text-color;
            }
            &::after {
               content: "";
               position: absolute;
               top: 36%;
               left: 75%;
               height: 3.2rem;
               width: 0.2rem;
               background: $white-text-color;
               transform: rotate(-45deg) translate(-50%, -50%);
            }
         }
      }
      & .fancybox-button svg {
         @media screen and (min-width: 667px) {
            display: none;
         }
      }
   }
}
.fancybox-button--arrow_left {
   position: relative;
   width: 6.4rem !important;
   height: 6.4rem !important;
   margin: 0;
   background: rgba(255, 255, 255, 0.5);
   border: 1px solid rgba(255, 255, 255, 0.2);
   backdrop-filter: blur(2rem);
   border-radius: 5rem;
   padding: 0 !important;
   @media screen and (min-width: 769px) {
      left: 8rem !important;
   }
   @media screen and (max-width: 666px) {
      display: none;
   }
   & div {
      display: none;
   }
   &::before {
      content: "";
      position: absolute;
      top: 57%;
      left: 44%;
      width: 1rem;
      height: 1px;
      transform: rotate(45deg);
      background-color: $white-text-color;
   }
   &::after {
      content: "";
      position: absolute;
      top: 45%;
      left: 43%;
      width: 1rem;
      height: 1px;
      transform: rotate(-45deg);
      background-color: $white-text-color;
   }
}
.fancybox-button--arrow_right {
   position: relative;
   width: 6.4rem !important;
   height: 6.4rem !important;
   margin: 0;
   background: rgba(255, 255, 255, 0.5);
   border: 1px solid rgba(255, 255, 255, 0.2);
   backdrop-filter: blur(2rem);
   border-radius: 5rem;
   padding: 0 !important;

   @media screen and (min-width: 769px) {
      right: 8rem !important;
   }
   @media screen and (min-width: 667px) and (max-width: 768px) {

   }
   @media screen and (max-width: 666px) {
      display: none;
   }
   & div {
      display: none;
   }
   &::before {
      content: "";
      position: absolute;
      top: 45%;
      left: 43%;
      width: 1rem;
      height: 1px;
      transform: rotate(45deg);
      background-color: $white-text-color;
   }
   &::after {
      content: "";
      position: absolute;
      top: 57%;
      left: 44%;
      width: 1rem;
      height: 1px;
      transform: rotate(-45deg);
      background-color: $white-text-color;
   }
}
/* ============================ template START ================================================================= */
.template-inner {
   position: relative;
   background: $dark-color;
   & .breadcrumbs {
      @extend %template-breadcrumbs;
   }
}
.template-img {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
   }
}
.template-wrapper {
   position: relative;
   z-index: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 2.4rem 0 10rem;
   @media screen and (max-width: 666px) {
      padding: 2rem 0 5rem;
   }
}
.template-content {
   background: rgba(166, 166, 166, 0.5);
   border: 1px solid rgba(255, 255, 255, 0.2);
   backdrop-filter: blur(2rem);
   /* Note: backdrop-filter has minimal browser support */
   border-radius: 0.6rem;
   padding: 5rem 7.5rem;
   max-width: 86rem;
   width: 100%;
   @media screen and (max-width: 666px) {
      padding: 2rem;
   }
   & > p {
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      font-size: 1.6rem;
      line-height: 2.6rem;
      text-align: center;
      color: $white-text-color;
      & > strong {
         font-weight: 500;
      }
   }
   & > h2 {
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      font-family: $family_RobotoCondensed;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: center;
      color: $white-text-color;
   }
}
.template-title {
   margin-bottom: 5rem;
   @media screen and (max-width: 666px) {
      margin-bottom: 2rem;
   }
   & > * {
      font-family: $family_RobotoCondensed;
      font-size: 4.8rem;
      line-height: 5.6rem;
      color: $white-text-color;
      text-align: center;
      @media screen and (max-width: 666px) {
         font-size: 4rem;
         line-height: 5rem;
      }
   }
}
.template-title-text {
   &__box {
      max-width: 86rem;
      width: 100%;
      &:not(:last-child) {
         margin-bottom: 7rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
   }
   &__title {
      position: relative;
      margin-bottom: 5rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 2.5rem;
      }
      &::after {
         content: "";
         position: absolute;
         bottom: -2.4rem;
         left: 50%;
         transform: translate(-50%, 0);
         width: 10rem;
         height: 2px;
         background-color: $accent;
         @media screen and (max-width: 666px) {
            bottom: -1.2rem;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 3.6rem;
         line-height: 4.2rem;
         text-align: center;
         color: $title-color;
      }
   }
   &__text {
      align-self: center;
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         text-align: center;
         & > * {
            color: $title-color;
         }
      }
   }
}
.template-wrapper-2 {
   display: flex;
   align-items: center;
   flex-direction: column;
   padding: 10rem 0;
   @media screen and (max-width: 666px) {
      padding: 5rem 0;
   }
}
.template-wrapper-4 {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 10rem 0;
   @media screen and (max-width: 666px) {
      padding: 5rem 0;
   }
}
.template-title-2 {
   &:not(:last-child) {
      margin-bottom: 7rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
   }
   @extend %title-h2;
   text-align: center;
}
.template-text-2 {
   @extend %title-h4;
   text-align: center;
}
.template-img-2 {
   width: 5rem;
   height: 5rem;
   &:not(:last-child) {
      margin-bottom: 4rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
   }
   & > * {
      object-fit: contain;
   }
}
.template-wrapper-slider {
   display: flex;
   align-items: center;
   @media screen and (max-width: 666px) {
      flex-direction: column;
   }
   &_reverse {
      @media screen and (min-width: 667px) {
         flex-direction: row-reverse;
      }
   }
}
//==== slider====
.template-slider {
   &__box {
      position: relative;
      max-width: 75rem;
      width: 100%;
      min-width: 0;
      @media screen and (min-width: 667px) {
         margin: 0 11rem 0 15rem;
         padding: 6rem 0 12.4rem 0;
      }
      @media screen and (max-width: 666px) {
         padding: 3rem 0;
      }
   }
   &__slide {
      &_wp-editor {
         display: flex;
         flex-direction: column;
         & > h2 {
            font-family: $family_RobotoCondensed;
            font-size: 3.6rem;
            line-height: 4.2rem;
            color: $title-color;
            &:not(:last-child) {
               margin-bottom: 3rem;
               @media screen and (max-width: 666px) {
                  margin-bottom: 1.5rem;
               }
            }
         }
         & > h3 {
            font-family: $family_RobotoCondensed;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 2.8rem;
            color: #2D3436;
            &:not(:last-child) {
               margin-bottom: 4rem;
               @media screen and (max-width: 666px) {
                  margin-bottom: 2rem;
               }
            }
         }
         & > h4 {
            font-family: $family_RobotoCondensed;
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.3rem;
            color: #57606F;
            &:not(:last-child) {
               margin-bottom: 3rem;
               @media screen and (max-width: 666px) {
                  margin-bottom: 1.5rem;
               }
            }
         }
         & > p {
            font-size: 1.8rem;
            line-height: 2.9rem;
            color: $title-color;
            &:not(:last-child) {
               margin-bottom: 3rem;
               @media screen and (max-width: 666px) {
                  margin-bottom: 1.5rem;
               }
            }
            & > strong {
               font-weight: 500;
               line-height: 3.2rem;
            }
            & > a {
               font-size: 1.8rem;
               line-height: 2.1rem;
               text-decoration-line: underline;
               color: $link-color;
            }
         }
         & > button {
            min-width: 18rem;
            max-width: max-content;
            background-color: $accent;
            border-radius: 5rem;
            text-align: center;
            padding: 2rem;
            transition: background-color .25s $time-distribution-function;
            @media screen and (max-width: 666px) {
               padding: 1rem;
               min-width: 14rem;
            }
            &:hover {
               background-color: $title-color;
            }
            & > a {
               font-family: $family_RobotoCondensed;
               font-size: 1.8rem;
               line-height: 2.1rem;
               letter-spacing: 0.05em;
               color: $white-text-color;
               @media screen and (max-width: 666px) {
                  font-size: 1.5rem;
                  line-height: 2rem;
               }
            }
         }
         & > ul{
            max-width: 60rem;
            width: 100%;
            padding-left: 2rem;
            &:not(:last-child) {
               margin-bottom: 5rem;
            }
            & > li {
               position: relative;
               &:not(:last-child) {
                  margin-bottom: 3rem;
                  @media screen and (max-width: 666px) {
                     margin-bottom: 1.5rem;
                  }
               }
               font-size: 1.8rem;
               line-height: 2.9rem;
               color: $title-color;
               & > mark {
                  background-color: transparent;
                  color: $primary-text-color;
               }
               & > a {
                  font-size: 1.8rem;
                  line-height: 2.1rem;
                  text-decoration-line: underline;
                  color: $link-color;
               }
               &::before {
                  content: "";
                  position: absolute;
                  top: 1.1rem;
                  left: -2rem;
                  width: 0.6rem;
                  height: 0.6rem;
                  background: $accent;
                  border-radius: 50%;
               }
            }
         }
         & > ol {
            max-width: 60rem;
            width: 100%;
            padding-left: 5rem;
            &:not(:last-child) {
               margin-bottom: 5rem;
            }
            counter-reset: myCounter;
            & > li {
               position: relative;
               &:not(:last-child) {
                  margin-bottom: 1.6rem;
               }
               font-size: 1.8rem;
               line-height: 2.9rem;
               color: $title-color;
               & > mark {
                  background-color: transparent;
                  color: $primary-text-color;
               }
               & > a {
                  font-size: 1.8rem;
                  line-height: 2.1rem;
                  text-decoration-line: underline;
                  color: $link-color;
               }
               &::before {
                  counter-increment: myCounter;
                  content: counter(myCounter);
                  color: $white-text-color;
                  background: $accent;
                  display: inline-block;
                  text-align: center;
                  font-weight: 700;
                  min-width: 3.5rem;
                  height: 3.5rem;
                  font-size: 1.6rem;
                  line-height: 3.5rem;
                  position: absolute;
                  top: 0;
                  left: -5rem;
               }
            }
         }
      }
   }
   &__navigation-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         display: none;
      }
   }
   &__button-prev{
      margin-right: 1.4rem!important;
   }
   &__button-prev,
   &__button-next {
      position: static;
      width: 6.4rem;
      height: 6.4rem;
      margin: 0;
      border-radius: 5rem;
      border: solid 1px $accent;
      background-color: $white-text-color;
      transition: background-color .25s $time-distribution-function;
      outline: none;
      &:hover {
         background-color: $accent;
         &::after {
            color: $white-text-color;
         }
      }
      &::after {
         font-size: 1.7rem;
         font-weight: 600;
         color: $accent;
         transition: color .25s $time-distribution-function;
      }
      &.swiper-button-disabled {
         background: #FEFEFE;
         border: 1px solid #F9DAD7;
         backdrop-filter: blur(2rem);
         /* Note: backdrop-filter has minimal browser support */
         border-radius: 5rem;
      }
   }
   &__pagination {
      display: flex;
      align-items: center;
      left: 0;
      @media screen and (max-width: 666px) {
         justify-content: center;
         top: auto;
         bottom: 0 !important;
      }
      @media screen and (min-width: 667px) {
         width: auto !important;
         bottom: auto !important;
         top: 0;
      }
      & .swiper-pagination-bullet {
         width: 0.6rem;
         height: 0.6rem;
         &.swiper-pagination-bullet-active {
            background-color: $accent;
            width: 1rem;
            height: 1rem;
         }
      }
   }
   &_reverse {
      &__box {
         position: relative;
         max-width: 75rem;
         width: 100%;
         min-width: 0;
         @media screen and (min-width: 667px) {
            margin: 0 11rem 0 15rem;
            padding: 6rem 0 12.4rem 0;
         }
         @media screen and (max-width: 666px) {
            padding: 3rem 0;
         }
      }
      &__slide {
         &_wp-editor {
            display: flex;
            flex-direction: column;
            & > h2 {
               font-family: $family_RobotoCondensed;
               font-size: 3.6rem;
               line-height: 4.2rem;
               color: $title-color;
               &:not(:last-child) {
                  margin-bottom: 3rem;
                  @media screen and (max-width: 666px) {
                     margin-bottom: 1.5rem;
                  }
               }
            }
            & > h3 {
               font-family: $family_RobotoCondensed;
               font-weight: 500;
               font-size: 2.4rem;
               line-height: 2.8rem;
               color: #2D3436;
               &:not(:last-child) {
                  margin-bottom: 4rem;
                  @media screen and (max-width: 666px) {
                     margin-bottom: 2rem;
                  }
               }
            }
            & > h4 {
               font-family: $family_RobotoCondensed;
               font-weight: 500;
               font-size: 2rem;
               line-height: 2.3rem;
               color: #57606F;
               &:not(:last-child) {
                  margin-bottom: 3rem;
                  @media screen and (max-width: 666px) {
                     margin-bottom: 1.5rem;
                  }
               }
            }
            & > p {
               font-size: 1.8rem;
               line-height: 2.9rem;
               color: $title-color;
               &:not(:last-child) {
                  margin-bottom: 3rem;
                  @media screen and (max-width: 666px) {
                     margin-bottom: 1.5rem;
                  }
               }
               & > strong {
                  font-weight: 500;
                  line-height: 3.2rem;
               }
               & > a {
                  font-size: 1.8rem;
                  line-height: 2.1rem;
                  text-decoration-line: underline;
                  color: $link-color;
               }
            }
            & > button {
               min-width: 18rem;
               max-width: max-content;
               background-color: $accent;
               border-radius: 5rem;
               text-align: center;
               padding: 2rem;
               transition: background-color .25s $time-distribution-function;
               @media screen and (max-width: 666px) {
                  padding: 1rem;
                  min-width: 14rem;
               }
               &:hover {
                  background-color: $title-color;
               }
               & > a {
                  font-family: $family_RobotoCondensed;
                  font-size: 1.8rem;
                  line-height: 2.1rem;
                  letter-spacing: 0.05em;
                  color: $white-text-color;
                  @media screen and (max-width: 666px) {
                     font-size: 1.5rem;
                     line-height: 2rem;
                  }
               }
            }
            & > ul, ol {
               max-width: 60rem;
               width: 100%;
               padding-left: 2rem;
               &:not(:last-child) {
                  margin-bottom: 5rem;
               }
               & > li {
                  position: relative;
                  &:not(:last-child) {
                     margin-bottom: 3rem;
                     @media screen and (max-width: 666px) {
                        margin-bottom: 1.5rem;
                     }
                  }
                  font-size: 1.8rem;
                  line-height: 2.9rem;
                  color: $title-color;
                  & > mark {
                     background-color: transparent;
                     color: $primary-text-color;
                  }
                  & > a {
                     font-size: 1.8rem;
                     line-height: 2.1rem;
                     text-decoration-line: underline;
                     color: $link-color;
                  }
                  &::before {
                     content: "";
                     position: absolute;
                     top: 1.1rem;
                     left: -2rem;
                     width: 0.6rem;
                     height: 0.6rem;
                     background: $accent;
                     border-radius: 50%;
                  }
               }
            }
         }
      }
      &__navigation-buttons {
         position: absolute;
         bottom: 0;
         left: 0;
         display: flex;
         align-items: center;
         @media screen and (max-width: 666px) {
            display: none;
         }
      }
      &__button-prev{
         margin-right: 1.4rem!important;
      }
      &__button-prev,
      &__button-next {
         position: static;
         width: 6.4rem;
         height: 6.4rem;
         margin: 0;
         border-radius: 5rem;
         border: solid 1px $accent;
         background-color: $white-text-color;
         transition: background-color .25s $time-distribution-function;
         outline: none;
         &:hover {
            background-color: $accent;
            &::after {
               color: $white-text-color;
            }
         }
         &::after {
            font-size: 1.7rem;
            font-weight: 600;
            color: $accent;
            transition: color .25s $time-distribution-function;
         }
         &.swiper-button-disabled {
            background: #FEFEFE;
            border: 1px solid #F9DAD7;
            backdrop-filter: blur(2rem);
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 5rem;
         }
      }
      &__pagination {
         display: flex;
         align-items: center;
         left: 0;
         @media screen and (max-width: 666px) {
            justify-content: center;
            top: auto;
            bottom: 0 !important;
         }
         @media screen and (min-width: 667px) {
            width: auto !important;
            bottom: auto !important;
            top: 0;
         }
         & .swiper-pagination-bullet {
            width: 0.6rem;
            height: 0.6rem;
            &.swiper-pagination-bullet-active {
               background-color: $accent;
               width: 1rem;
               height: 1rem;
            }
         }
      }
   }
}
.template-img-slider {
   position: relative;
   max-width: 83rem;
   width: 100%;
   @media screen and (min-width: 667px) {
      height: 1px;
      max-height: 100.8rem;
      min-height: 100.8rem;
   }
   @media screen and (max-width: 666px) {
      margin-top: 2.5rem;
   }
   &_right {
      @media screen and (min-width: 667px) {
         margin-right: -8rem;
      }
      & .template-img-slider__text {
         left: -3.5rem;
      }
   }
   &_left {
      @media screen and (min-width: 667px) {
         margin-left: -8rem;
      }
      & .template-img-slider__text {
         right: -3.5rem;
      }
   }
   & > * {
      @media screen and (max-width: 666px) {
         object-fit: contain;
      }
   }
   &__text {
      position: absolute;
      bottom: 0;
      display: inline-block;
      writing-mode: tb;
      transform: rotate(-180deg);
      @media screen and (max-width: 666px) {
         display: none;
      }
      & > * {
         font-size: 1.8rem;
         line-height: 2.1rem;
         text-transform: uppercase;
         color: #929A9C;
      }
   }
}
.template-list {
   display: flex;
   justify-content: center;
   max-width: 146rem;
   width: 100%;
   &_2 {
      justify-content: space-between;
   }
   &_3 {
      @media screen and (max-width: 479px) {
         flex-direction: column;
         align-items: center;
      }
   }
   &_4 {
      flex-wrap: wrap;
   }
   &_6 {
      flex-wrap: wrap;
   }
}
.template-item {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   &_2 {
      max-width: 60rem;
      width: 100%;
      flex: 0 1 50%;
      &:not(:last-child) {
         margin-right: 26rem;
         @media screen and (max-width: 666px) {
            margin-right: 5rem;
         }
         &::after {
            content: "";
            position: absolute;
            top: 0;
            right: -13rem;
            width: 1px;
            height: 100%;
            background-color: #D8E2ED;
            @media screen and (max-width: 666px) {
               right: -2.5rem;
            }
         }
      }
   }
   &_3 {
      max-width: 41rem;
      width: 100%;
      @media screen and (max-width: 479px) {
         max-width: 30rem;
         width: 100%;
      }
      &:not(:last-child) {
         margin-right: 11rem;
         &::before {
            content: "";
            position: absolute;
            top: 0;
            right: -5.5rem;
            width: 1px;
            height: 100%;
            background: #D8E2ED;
            @media screen and (max-width: 666px) {
               right: -1rem
            }
            @media screen and (max-width: 479px) {
               top: auto;
               right: auto;
               bottom: -2rem;
               height: 1px;
               width: 100%;
            }
         }
         @media screen and (max-width: 666px) {
            margin-right: 2rem;
         }
         @media screen and (max-width: 479px) {
            margin-bottom: 4rem;
            margin-right: 0;
         }
      }
   }
   &_4 {
      flex: 0 1 50%;
      &:nth-child(1) {
         border-right: #D8E2ED solid 1px;
         border-bottom: #D8E2ED solid 1px;
         padding-bottom: 8rem;
      }
      &:nth-child(2) {
         border-bottom: #D8E2ED solid 1px;
         padding-bottom: 8rem;
      }
      &:nth-child(3) {
         border-right: #D8E2ED solid 1px;
         padding-top: 8rem;
      }
      &:nth-child(4) {
         margin-top: 8rem;
      }
   }
   &_6 {
      flex: 0 1 33.33%;
      @media screen and (max-width: 479px) {
         flex: 0 1 50%;

      }
      &:nth-child(1) {
         border-right: #D8E2ED solid 1px;
         border-bottom: #D8E2ED solid 1px;
         padding-bottom: 6rem;
      }
      &:nth-child(2) {
         border-right: #D8E2ED solid 1px;
         border-bottom: #D8E2ED solid 1px;
         padding-bottom: 6rem;
         @media screen and (max-width: 479px) {
            border-right: none;
         }
      }
      &:nth-child(3) {
         border-bottom: #D8E2ED solid 1px;
         padding-bottom: 6rem;
         @media screen and (max-width: 479px) {
            border-right: #D8E2ED solid 1px;
            padding-top: 6rem;
         }
      }
      &:nth-child(4) {
         padding-top: 6rem;
         border-right: #D8E2ED solid 1px;
         @media screen and (max-width: 479px) {
            border-right: none;
            border-bottom: #D8E2ED solid 1px;
            padding-bottom: 6rem;
         }
      }
      &:nth-child(5) {
         padding-top: 6rem;
         border-right: #D8E2ED solid 1px;
      }
      &:nth-child(6) {
         padding-top: 6rem;
      }
   }
}
/* ============================ template END ================================================================= */

@mixin font-face($style-name, $file:$style-name, $weight:normal, $style:normal) {
   $filepath: "/static/fonts/" + $file;
   @font-face {
      font-family: quote($style-name);
      src: url($filepath + ".woff") format('woff'),
         //url($filepath + ".woff2") format('woff2'),
      url($filepath + ".ttf") format('truetype');
      font-weight: $weight;
      font-style: $style;
      font-display: swap;
   }
}

@include font-face($family_RobotoCondensed, 'RobotoCondensed-Regular', 400, normal);
@include font-face($family_RobotoCondensed, 'RobotoCondensed-Medium', 500, normal);
@include font-face($family_RobotoCondensed, 'RobotoCondensed-Bold', 700, normal);

@include font-face($family_Roboto, 'Roboto-Regular', 400, normal);
@include font-face($family_Roboto, 'Roboto-Medium', 500, normal);


