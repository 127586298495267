@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ blog-post START ================================================================= */
.blog-post {
   &__wrapper {
      display: flex;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
      @media screen and (min-width: 667px) {
         padding-bottom: 5rem;
      }
   }
   &__box {
      width: 100%;
   }
   &__title {
      @extend %title-h2;
      text-align: center;
      margin: 2.4rem 0 6rem;
      @media screen and (max-width: 666px) {
         margin: 2rem 0;
      }
   }
   &__content {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__wp-editor {
      max-width: 101rem;
      width: 100%;
      @media screen and (min-width: 667px) {
         margin-right: 9rem;
      }
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
      & > p {
         &:not(:last-child) {
            margin-bottom: 1.6rem;
         }
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $title-color;
         & > a {
            color: $link-color;
         }
      }
      & > a {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $link-color;
      }
      & > img {
         min-height: 25.6rem;
         max-height: 25.6rem;
         height: 1px;
         margin-top: 5rem;
         &:not(:last-child) {
            margin-bottom: 5rem;
         }
      }
      & > h3 {
         font-family: $family_RobotoCondensed;
         font-weight: 500;
         font-size: 2.4rem;
         line-height: 2.8rem;
         color: $dark-color;
         margin-top: 5rem;
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
      & > h4 {
         font-family: $family_RobotoCondensed;
         font-weight: 500;
         font-size: 2rem;
         line-height: 2.3rem;
         color: $title-color;
         margin-top: 5rem;
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
      & > ul {
         padding-left: 2rem;
         &:not(:last-child) {
            margin-bottom: 5rem;
         }
         & > li {
            position: relative;
            &:not(:last-child) {
               margin-bottom: 1.6rem;
            }
            font-size: 1.6rem;
            line-height: 2.6rem;
            color: $title-color;
            &::before {
               content: "";
               position: absolute;
               top: 50%;
               left: -2rem;
               transform: translateY(-50%);
               width: 0.6rem;
               height: 0.6rem;
               background: $accent;
               border-radius: 50%;
            }
         }
      }
      & > ol {
         padding-left: 5rem;
         &:not(:last-child) {
            margin-bottom: 5rem;
         }
         counter-reset: myCounter;
         & > li {
            position: relative;
            &:not(:last-child) {
               margin-bottom: 1.6rem;
            }
            font-size: 1.6rem;
            line-height: 2.6rem;
            color: $title-color;
            &::before {
               counter-increment: myCounter;
               content: counter(myCounter);
               color: $white-text-color;
               background: $accent;
               display: inline-block;
               text-align: center;
               font-weight: 700;
               min-width: 3.5rem;
               height: 3.5rem;
               font-size: 1.6rem;
               line-height: 3.5rem;
               position: absolute;
               top: 0;
               left: -5rem;
            }
         }
      }
   }
}

.info-post {
   display: flex;
   align-items: center;
   @media screen and (max-width: 666px) {
      flex-direction: column;
   }
   &:not(:last-child) {
      margin-bottom: 6rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
   }
   &__list {
      display: flex;
      @media screen and (min-width: 667px) {
         align-items: center;
      }
      @media screen and (max-width: 666px) {
         flex-wrap: wrap;
         margin: -1rem 0 0 -1rem;
      }
      &:not(:last-child) {
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
   }
   &__item {
      background: $bg-color;
      border-radius: 2rem;
      &:not(:last-child) {
         @media screen and (min-width: 667px) {
            margin-right: 1rem;
         }
      }
      @media screen and (max-width: 666px) {
         margin: 1rem 0 0 1rem;
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 1.4rem;
         line-height: 1.6rem;
         padding: 1.2rem 1.4rem;
      }
   }
   &__box-name {
      display: flex;
      align-items: center;

   }
   &__box-date {
      display: flex;
      align-items: center;
      @media screen and (min-width: 667px) {
         margin-right: 3rem;

      }
      @media screen and (max-width: 666px) {
         margin-right: 0.5rem;
      }
   }
   &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (min-width: 667px) {
         margin: 0 15rem 0 auto;
      }
      @media screen and (max-width: 666px) {
         max-width: 46rem;
         width: 100%;
      }
   }
   &__photo {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 1.4rem;
      flex-shrink: 0;
      @media screen and (max-width: 666px) {
         width: 3rem;
         height: 3rem;
         margin-right: 0.5rem;
      }
   }
   &__icon {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
      @media screen and (max-width: 666px) {
         width: 1.2rem;
         height: 1.2rem;
      }
      & > * {
         display: inline-block;
         width: 1.8rem;
         height: 1.8rem;
         @media screen and (max-width: 666px) {
            width: 1.2rem;
            height: 1.2rem;
         }
      }
   }
   &__name {
      & > * {
         font-weight: 500;
         font-size: 1.2rem;
         line-height: 1.4rem;
         text-transform: uppercase;
         @media screen and (max-width: 666px) {
            font-size: 1.1rem;
         }
      }
   }
   &__date {
      & > * {
         font-weight: 500;
         font-size: 1.2rem;
         line-height: 1.4rem;
         text-transform: uppercase;
         @media screen and (max-width: 666px) {
            font-size: 1.1rem;
         }
      }
   }
}

.article {
   max-width: 46rem;
   width: 100%;
   @media screen and (max-width: 666px) {
      margin: 0 auto;
   }
   @media screen and (min-width: 667px) {
      margin-right: 5rem;
   }
   &__title {
      @extend %title-h2;
      margin-bottom: 4rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
   }
   &__link {
      display: flex;
      flex-direction: column;
      transition: box-shadow .25s $time-distribution-function;
      &:hover {
         box-shadow: 0 1rem 3rem rgba(47, 53, 66, 0.1);
         .article__sub-title {
            & > * {
               color: $accent;
            }
         }
      }
   }
   &__img {
      max-height: 31.4rem;
      min-height: 31.4rem;
      height: 1px;
      @media screen and (max-width: 666px) {
         max-height: 25rem;
         min-height: 25rem;
      }
   }
   &__photo {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 1.4rem;
      flex-shrink: 0;
      @media screen and (max-width: 666px) {
         width: 3rem;
         height: 3rem;
         margin-right: 0.5rem;
      }
   }
   &__icon {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
      @media screen and (max-width: 666px) {
         width: 1.2rem;
         height: 1.2rem;
      }
      & > * {
         display: inline-block;
         width: 1.8rem;
         height: 1.8rem;
         @media screen and (max-width: 666px) {
            width: 1.2rem;
            height: 1.2rem;
         }
      }
   }
   &__sub-title {
      &:not(:last-child) {
         margin-bottom: 3.6rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
      @extend %title-h4;
      & > * {
         transition: color .25s $time-distribution-function;
      }
   }
   &__text {
      margin-top: auto;
      &:not(:last-child) {
         margin-bottom: 3rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 1.5rem;
         }
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $secondary;
      }
   }
   &__box {
      display: flex;
      flex-direction: column;
      padding: 0 3rem 3rem;
      height: 100%;
      @media screen and (max-width: 666px) {
         padding: 0 1.5rem 1.5rem;
      }
   }
   &__box-name {
      display: flex;
      align-items: center;
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
      @media screen and (max-width: 666px) {
         margin-right: 0.5rem;
      }
   }
   &__box-date {
      display: flex;
      align-items: center;
   }
   &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: auto;
   }
   &__name {
      & > * {
         font-weight: 500;
         font-size: 1.2rem;
         line-height: 1.4rem;
         text-transform: uppercase;
         @media screen and (max-width: 666px) {
            font-size: 1.1rem;
         }
      }
   }
   &__date {
      & > * {
         font-weight: 500;
         font-size: 1.2rem;
         line-height: 1.4rem;
         text-transform: uppercase;
         @media screen and (max-width: 666px) {
            font-size: 1.1rem;
         }
      }
   }
   &__wrapper {
      position: relative;
      &::before {
         content: "";
         position: absolute;
         top: 3rem;
         right: 0;
         width: 6rem;
         height: 4rem;
         background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
         @media screen and (max-width: 666px) {
            top: 1.5rem;
         }
      }
   }
   &__list {
      display: flex;
      align-items: center;
      overflow: auto;
      max-width: 100%;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
         display: none;
      }
      margin: 3rem 0 3.6rem 3rem;
      @media screen and (max-width: 666px) {
         margin: 1.5rem 0 2rem 1.5rem;
      }
   }
   &__item {
      background: $bg-color;
      border-radius: 2rem;
      flex: 0 0 auto;
      &:not(:last-child) {
         margin-right: 1rem;
      }
      &:nth-child(4) {


      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 1.4rem;
         line-height: 1.6rem;
         padding: 1.2rem 1.4rem;
      }
   }
}
/* ============================ blog-post END ================================================================= */