@import 'variable.scss';
//шаблон класів для елементів з однаковими чи сходжими стилями

/* ============================ styles from placeholder START ================================================================= */
%text-primary {
   & > * {
      font-size: 1.8rem;
      line-height: 2.9rem;
      color: $title-color;
   }
}

%title-h1 {
   & > * {
      font-family: $family_RobotoCondensed;
      font-size: 4.8rem;
      line-height: 5.6rem;
      color: $title-color;
      @media screen and (max-width: 666px) {
         font-size: 4rem;
         line-height: 5rem;
      }
   }
}

%title-h2 {
   & > * {
      font-family: $family_RobotoCondensed;
      font-size: 3.6rem;
      line-height: 4.2rem;
      color: $title-color;
   }
}

%title-h3 {
   & > * {
      font-family: $family_RobotoCondensed;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $title-color;
   }
}

%title-h4 {
   & > * {
      font-family: $family_RobotoCondensed;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;
      color: $title-color;
      @media screen and (max-width: 666px) {
         font-size: 1.8rem;
         line-height: 2.2rem;
      }
   }
}

%template-breadcrumbs{
   & > * {
      & > * {
         &:not(:last-child) {
            margin-right: 1.5rem;
            &::after {
               color: $white-text-color;
            }
            & > * {
               color: $white-text-color;

               &:hover {
                  color: $accent;
               }
            }
         }
         & > * {
            color: $primary-text-color;
         }
      }
   }
}

/* ============================ styles from placeholder END ================================================================= */
