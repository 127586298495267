@import '../app/variable.scss';
@import "service-hero/service-hero.scss";
@import "service-quantum/service-quantum.scss";
@import "../components/button/button.scss";// імпортуємо на потрібну сторінку
@import "../components/input/input.scss";
//@import "../header/header.scss"; // дублював стилі на index.html
//@import "../footer/footer.scss";

.test {
	&__title {
		color: red;
		font-size: 3rem;
	}
}

.hero {
	&__title {
		font-size: 5rem;

	}
	&__img {
		width: 15rem;
		height: 15rem;
		// буде в dist/styles/img/sprite_zz_item_2.jpg якщо залишем [npm run start]
		// не буде підключено якщо буде [npm run do]
		background: url("img/sprite/item_2.jpg") no-repeat center;
		background-size: cover; //розтягує картинку
	}
	&__img-2{
		width: 15rem;
		height: 15rem;
		background: url("img/sprite/rub.svg") no-repeat center;
		background-size: cover;
	}
	&__picture {
		& > * {
			max-width: 130rem;
			width: 100%;
			height: 30rem;
			object-fit: cover;
			object-position: center;
		}
	}
	&__icon{
		cursor: pointer;
		fill: #ae276d;
		transition: fill .25s $time-distribution-function;
		&:hover,
		&:focus{
		    fill: #8ab065;
		}
	}
}

.individual-projects {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&__item {
		flex: 0 1 45%;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		&:not(:last-child) {
			margin-bottom: 6.8rem;
		}
	}
	&__title {
		margin-bottom: 2rem;
		& > * {
			font-weight: 600;
			font-size: 1.8rem;
			line-height: 2.8rem;

		}
	}
	&__text {
		margin-bottom: 2rem;
		& > * {
			font-size: 1.2rem;
			line-height: 1.6rem;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		margin-top: auto;
		//ховер на текст силки
		&:hover,
		&:focus {
			& > * {
				color: #8ab065;
			}
		}
		& > * {
			color: $accent;
			transition: color .25s $time-distribution-function;
		}
		//ховер на svg картинку
		&:hover,
		&:focus {
			.individual-projects__icon {
				stroke: #8ab065;
			}
		}
	}
	&__icon {
		max-width: 1.6rem;
		max-height: 1.6rem;
		stroke: $accent;
		transition: stroke .25s $time-distribution-function;
	}
}

