@import '../app/variable.scss';
@import "../components/form/form.scss";
//@import "../components/select-dropdown/select-dropdown.scss"; щоб були видні стилі

/* ============================ index START ================================================================= */
.best-fences {
   &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5rem 1.5rem;
      @media screen and (max-width: 666px) {
         position: relative;
         flex-direction: column;
         padding: 4rem 1.5rem;
      }
   }
   &__content {
      max-width: 74rem;
      width: 100%;
      flex: 0 1 50%;
      margin: 0 1rem 0 0;
      @media screen and (max-width: 666px) {
         margin: 0 0 2rem 0;
      }
   }
   &__title {
      margin-bottom: 3rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 1.5rem;
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-weight: 700;
         font-size: 6rem;
         line-height: 7rem;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 4rem;
            line-height: 5rem;
         }
      }
   }
   &__text {
      margin-bottom: 5rem;
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $primary-text-color;
      }
   }
   &__box {
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         justify-content: center;
      }
   }
   &__link-order.link {
      min-width: 17rem;
      max-width: none;
      width: auto;
      padding: 2rem;
      background-color: $title-color;
      flex: 0 1 auto;
      transition: background-color .25s $time-distribution-function, border .25s $time-distribution-function;
      border: 2px solid $title-color;
      @media screen and (max-width: 666px) {
         min-width: 14rem;
         padding: 1rem;
      }
      &:not(:last-child) {
         margin-right: 2rem;
         @media screen and (max-width: 666px) {
            margin-right: 1rem;
         }
      }
      &:hover {
         background-color: $accent;
         border: 2px solid $accent;
      }
   }
   &__link-portfolio.link {
      min-width: 17rem;
      max-width: none;
      width: auto;
      padding: 2rem;
      background-color: $white-text-color;
      border: 2px solid $title-color;
      flex: 0 1 auto;
      transition: border .25s $time-distribution-function;
      @media screen and (max-width: 666px) {
         min-width: 14rem;
         padding: 1rem;
      }
      &:hover {
         border-color: $accent;
         & > * {
            color: $accent;
         }

      }
      & > * {
         transition: color .25s $time-distribution-function;
         color: $title-color;
      }
   }
   &__slider {
      position: relative;
      height: 86rem;
      max-width: 88rem;
      width: 100%;
      min-width: 0; //fix bag
      @media screen and (min-width: 667px) {
         flex: 0 1 50%;
      }
      @media screen and (max-width: 666px) {
         height: 40rem;
      }
   }
}

.slider-best-fences {
   height: 40rem;
   @media screen and (min-width: 667px) {
      height: 86rem;
   }
   &__img {
      height: 100%;
   }
   &__button-prev,
   &__button-next {
      position: static;
      width: 6.4rem;
      height: 6.4rem;
      margin: 0;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2rem);
      border-radius: 5rem;
      &::after {
         font-size: 1.7rem;
         font-weight: 600;
         color: $white-text-color;
      }
   }
   &__button-prev {
      transform: rotate(90deg);
      margin-bottom: 2.6rem;
   }
   &__button-next {
      transform: rotate(90deg);
   }
   &__slide {
      min-width: 0; //fix bag
   }
   &__navigation-buttons {
      position: absolute;
      top: 50%;
      right: 5rem;
      z-index: 1;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 666px) {
         display: none;
      }
   }
   &__pagination {
      z-index: 1;
      bottom: 1.3rem !important;
      & .swiper-pagination-bullet-active {
         background-color: $accent;
      }
      @media screen and (min-width: 667px) {
         display: none;
      }
   }
}

.gallery {
   &__container {
      padding: 10rem 1.5rem;
      @media screen and (max-width: 666px) {
         position: relative;
         padding: 5rem 1.5rem;
      }
   }
   &__title {
      margin-bottom: 5rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 2.5rem;
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 4.8rem;
         line-height: 5.6rem;
         text-align: center;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 4rem;
            line-height: 5rem;
         }
      }
   }
}

.slider-gallery {
   height: 78rem;
   width: 100%;
   margin-bottom: 3rem;
   @media screen and (max-width: 666px) {
      margin-bottom: 0;
      height: 40rem;
   }
   &__img {
      height: 100%;
   }
   &__button-prev {
      left: 5rem;
   }
   &__button-next {
      right: 5rem;
   }
   &__button-prev,
   &__button-next {
      width: 6.4rem;
      height: 6.4rem;
      margin: 0;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2rem);
      border-radius: 5rem;
      &::after {
         font-size: 1.7rem;
         font-weight: 600;
         color: $white-text-color;
      }
      @media screen and (max-width: 666px) {
         display: none;
      }
   }
   &__pagination {
      bottom: 2rem !important;
      & .swiper-pagination-bullet-active {
         background-color: $accent;
      }
      @media screen and (min-width: 667px) {
         display: none;
      }
   }
}

.slider-in-gallery {
   width: 100%;
   height: 8rem;
   @media screen and (max-width: 666px) {
      display: none;
   }
   & .swiper-slide {
      opacity: 0.4;
   }
   & .swiper-slide-thumb-active {
      opacity: 1;
   }
   &__slide {
      max-width: 14.8rem;
      width: 100%;
      cursor: pointer;
   }
   &__img {
      height: 100%;
   }
}

.calculator {
   &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 7rem 1.5rem;
      background-color: $title-color;
      @media screen and (max-width: 666px) {
         padding: 3rem 1.5rem;
      }
   }
   &__title {
      position: relative;
      max-width: 86rem;
      width: 100%;
      margin-bottom: 7rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 4rem;
      }
      &::after {
         content: "";
         position: absolute;
         bottom: -3.4rem;
         left: 50%;
         transform: translate(-50%, 0);
         width: 10rem;
         height: 2px;
         background-color: $white-text-color;
         @media screen and (max-width: 666px) {
            bottom: -2rem;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 3.6rem;
         line-height: 4.2rem;
         text-align: center;
         color: $white-text-color;
         @media screen and (max-width: 666px) {
            font-size: 3rem;
            line-height: 3.6rem;
         }
      }
   }
   &__link.link {
      min-width: 17rem;
      max-width: none;
      width: auto;
      padding: 2rem 5rem;
      background-color: $white-text-color;
      @media screen and (max-width: 666px) {
         padding: 1rem 2rem;
      }
      &:hover {
         & > * {
            color: $accent;
         }
      }
      & > * {
         transition: color .25s $time-distribution-function;
         color: $title-color;
      }
   }
}

.testimonials {
   margin-top: 7rem;
   @media screen and (max-width: 666px) {
      margin-top: 3.5rem;
   }
   &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10rem 1.5rem;
      background: #F7F9FB;
      @media screen and (max-width: 666px) {
         padding: 5rem 1.5rem;
      }
   }
   &__wrapper {
      max-width: 86rem;
      width: 100%;
   }
   &__title {
      position: relative;
      margin-bottom: 7.6rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 4rem;
      }
      &::after {
         content: "";
         position: absolute;
         bottom: -2.4rem;
         left: 50%;
         transform: translate(-50%, 0);
         width: 10rem;
         height: 2px;
         background-color: $accent;
         @media screen and (max-width: 666px) {
            bottom: -1.5rem;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 4.8rem;
         line-height: 5.6rem;
         text-align: center;
         color: $title-color;
         @media screen and (max-width: 666px) {
            font-size: 4rem;
            line-height: 5rem;
         }
      }
   }
   &__btn-box {
      display: flex;
      align-items: center;
      margin-bottom: 5rem;
      @media screen and (min-width: 667px) {
         justify-content: center;

      }
      @media screen and (max-width: 666px) {
         -ms-overflow-style: none; /* IE and Edge */
         scrollbar-width: none; /* Firefox */

         /* Hide scrollbar for Chrome, Safari and Opera */
         &::-webkit-scrollbar {
            display: none;
         }
         margin-bottom: 2.5rem;
         overflow: auto;
         max-width: 100%;
         width: 100%;
      }
   }
   &__btn {
      min-width: 17rem;
      padding: 2rem;
      flex: 0 1 auto;
      border-radius: 5rem;
      border: solid 2px $primary-text-color;
      transition: .25s $time-distribution-function;
      outline: none;
      @media screen and (max-width: 666px) {
         min-width: 15rem;
         padding: 1rem 2rem;
      }
      &:not(:last-child) {
         margin-right: 2rem;
         @media screen and (max-width: 666px) {
            margin-right: 1rem;
         }
      }
      &:hover {
         border-color: $title-color;
         background-color: $title-color;
         & > * {
            color: $white-text-color;
         }
      }
      &.active {
         border: solid 2px $title-color;
         background-color: $title-color;
         & > * {
            color: $white-text-color;
         }
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 1.8rem;
         line-height: 2.1rem;
         letter-spacing: 0.05em;
         color: $primary-text-color;
         transition: color .25s $time-distribution-function;
         @media screen and (max-width: 666px) {
            font-size: 1.5rem;
            line-height: 2rem;
         }
      }
   }
   &__text {
      & > * {
         font-size: 2.4rem;
         line-height: 3.8rem;
         text-align: center;
         @media screen and (max-width: 666px) {
            font-size: 1.8rem;
            line-height: 2.7rem;
         }
      }
   }
}

.manufacturing {
   &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10rem 1.5rem;
      @media screen and (max-width: 666px) {
         padding: 3rem 1.5rem;
      }
   }
   &__title {
      margin-bottom: 7rem;
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 3.6rem;
         line-height: 4.2rem;
         text-align: center;
         color: $title-color;
      }
   }
   &__list {
      max-width: 115.4rem;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -1rem 0 0 -1rem;
      @media screen and (max-width: 666px) {
         flex-wrap: wrap;
      }
      @media screen and (min-width: 667px) {
         justify-content: space-between;
      }
   }
   &__item {
      display: flex;
      align-items: center;
      margin: 1rem 0 0 1rem;
      width: calc(100% / 4 - 1rem);
      @media screen and (max-width: 666px) {
         width: calc(100% / 2 - 1rem);
      }
      & > * {
         font-weight: 500;
         font-size: 1.8rem;
         line-height: 2.1rem;
         @media screen and (max-width: 666px) {
            font-size: 1.5rem;
            line-height: 2rem;
         }
      }
   }
   &__img {
      margin-right: 2rem;
      width: 5rem;
      height: 5rem;
      @media screen and (max-width: 666px) {
         margin-right: 1rem;
         width: 2rem;
         height: 2rem;
         flex-shrink: 0;
      }
   }
}
/* ============================ index END ================================================================= */



