@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ blog START ================================================================= */
.blog {
   &__wrapper {
      padding: 2.4rem 0 5rem;
   }
   &__title {
      &:not(:last-child) {
         margin-bottom: 7rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 4rem;
         }
      }
      @extend %title-h1;
      color: $dark-color;
      text-align: center;
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -4rem 0 0 -4rem;
      @media screen and (max-width: 666px) {
         margin: -2rem 0 0 -2rem;
      }
      @media screen and (max-width: 599px) {
         flex-direction: column;
         align-items: center;
         margin: 0;
      }
   }
   &__item {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3 - 4rem);
      margin: 4rem 0 0 4rem;
      border: 1px solid #E3EAF2;
      transition: all .25s $time-distribution-function;
      @media screen and (max-width: 666px) {
         margin: 2rem 0 0 2rem;
         width: calc(100% / 2 - 2rem);
      }
      @media screen and (max-width: 599px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
      &:hover {
         border: 1px solid transparent;
      }
   }
   &__link {
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: box-shadow .25s $time-distribution-function;
      &:hover {
         box-shadow: 0 1rem 3rem rgba(47, 53, 66, 0.1);
         .blog__sub-title {
            & > * {
               color: $accent;
            }
         }
      }
   }
   &__img {
      min-height: 31.4rem;
      max-height: 31.4rem;
      height: 1px;
      @media screen and (max-width: 666px) {
         min-height: 25rem;
         max-height: 25rem;
      }
   }
   &__photo {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 1.4rem;
      flex-shrink: 0;
      @media screen and (max-width: 666px) {
         width: 3rem;
         height: 3rem;
         margin-right: 0.5rem;
      }
   }
   &__icon {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
      @media screen and (max-width: 666px) {
         width: 1.2rem;
         height: 1.2rem;
      }
      & > * {
         display: inline-block;
         width: 1.8rem;
         height: 1.8rem;
         @media screen and (max-width: 666px) {
            width: 1.2rem;
            height: 1.2rem;
         }
      }
   }
   &__sub-title {
      &:not(:last-child) {
         margin-bottom: 3.4rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
      @extend %title-h3;
      & > * {
         transition: color .25s $time-distribution-function;
      }
   }
   &__name {
      & > * {
         font-weight: 500;
         font-size: 1.2rem;
         line-height: 1.4rem;
         text-transform: uppercase;
         @media screen and (max-width: 666px) {
            font-size: 1.1rem;
         }
      }
   }
   &__text {
      margin-top: auto;
      &:not(:last-child) {
         margin-bottom: 3rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 1.5rem;
         }
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $secondary;
      }
   }
   &__date {
      & > * {
         font-weight: 500;
         font-size: 1.2rem;
         line-height: 1.4rem;
         text-transform: uppercase;
         @media screen and (max-width: 666px) {
            font-size: 1.1rem;
         }
      }
   }
   &__box {
      display: flex;
      flex-direction: column;
      padding: 4rem 4rem 3rem;
      height: 100%;
      @media screen and (max-width: 666px) {
         padding: 1rem;
      }
   }
   &__box-name {
      display: flex;
      align-items: center;
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
      @media screen and (max-width: 666px) {
         margin-right: 0.5rem;
      }
   }
   &__box-date {
      display: flex;
      align-items: center;
   }
   &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: auto;

   }
   &__pagination {
      margin-top: 5rem;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 666px) {
         margin-top: 2.5rem;
      }
      .page-numbers {
         font-size: 2rem;
         line-height: 2.8rem;
         padding: 1rem;
         &:not(:last-child) {
            margin-right: 2rem;
         }
      }
      .prev {
         &.page-numbers {
         }
      }
      .next {
         &.page-numbers {

         }
      }
   }
}
/* ============================ blog END ================================================================= */