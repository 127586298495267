@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ fortress START ================================================================= */
.info-fortress {
   &__wrapper {
      margin: 5rem 0 10rem;
      @media screen and (max-width: 666px) {
         margin: 5rem 0;
      }
   }¬
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}
/* ============================ fortress END ================================================================= */