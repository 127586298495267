@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ about START ================================================================= */
.numbers {
   &__wrapper {
      display: flex;
      justify-content: center;
      padding: 15rem 0 10rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 0;
      }
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      margin: -6rem 0 0 -6rem;
      max-width: 132.6rem;
      width: 100%;
      @media screen and (max-width: 666px) {
         margin: -3rem 0 0 -3rem;
      }
      @media screen and (max-width: 479px) {
         flex-direction: column;
         align-items: center;
         margin: 0;
      }
   }
   &__item {
      display: flex;
      align-items: center;
      width: calc(100% / 4 - 6rem);
      margin: 6rem 0 0 6rem;
      @media screen and (max-width: 666px) {
         margin: 3rem 0 0 3rem;
         width: calc(100% / 2 - 3rem);
      }
      @media screen and (max-width: 479px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
   }
   &__img {
      width: 5rem;
      height: 5rem;
      margin-right: 3rem;
      flex-shrink: 0;
      & > * {
         object-fit: contain;
      }
   }
   &__number {
      @extend %title-h1;
      &:not(:last-child) {
         margin-bottom: 1rem;
      }
   }
   &__text {
      & > * {
         font-family: $family_RobotoCondensed;
         font-size: 1.8rem;
         line-height: 2.1rem;
         color: $title-color;
      }
   }
   &__box {
      display: flex;
      flex-direction: column;
   }

}

.our {
   &__wrapper {
      display: flex;
      align-items: center;
      padding: 5rem 0;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
   }
   &__item {
      display: flex;
      padding-bottom: 6rem;
      border-bottom: solid 1px #E3EAF2;
      &:not(:last-child) {
         margin-bottom: 6rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
      @media screen and (max-width: 666px) {
         padding-bottom: 3rem;
         flex-direction: column;
      }
      @media screen and (min-width: 667px) {
         align-items: center;
      }
   }
   &__img {
      max-width: 83rem;
      width: 100%;
      @media screen and (min-width: 667px) {
         margin-right: -8rem;
         height: 1px;
         max-height: 100.8rem;
         min-height: 100.8rem;
      }
      & > * {
         @media screen and (max-width: 666px) {
            object-fit: contain;
         }
      }
   }
   &__icon {
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
      flex-shrink: 0;
      & > * {
         object-fit: contain;
      }
   }
   &__content {
      max-width: 74.8rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 667px) {
         margin: 0 11rem 0 15rem;
      }
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
   }
   &__btn-box {
      display: flex;
      border-bottom: #E3EAF2 solid 2px;
      &:not(:last-child) {
         margin-bottom: 7rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 4rem;
         }
      }
      @media screen and (min-width: 375px) and (max-width: 666px) {
         justify-content: space-evenly;
      }
      @media screen and (min-width: 667px) {
         max-width: 56rem;
         width: 100%;
      }
      @media screen and (max-width: 666px) {
         -ms-overflow-style: none; /* IE and Edge */
         scrollbar-width: none; /* Firefox */

         /* Hide scrollbar for Chrome, Safari and Opera */
         &::-webkit-scrollbar {
            display: none;
         }
         overflow: auto;
         max-width: 100%;
         width: 100%;
      }
   }
   &__icon-box {
      display: flex;
      align-items: center;
      @media screen and (min-width: 667px) {
         margin-right: 10%;
         flex: 0 1 30%;
      }
      &:not(:last-child) {
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
   }
   &__btn {
      @extend %title-h2;
      position: relative;
      padding: 0 1rem 2rem 1rem;
      @media screen and (max-width: 666px) {
         flex: 0 0 auto;
         min-width: 15rem;
      }
      @media screen and (min-width: 667px) {
         flex: 0 1 50%;
      }
      &.active {
         & > * {
            color: $accent;
         }
         @media screen and (min-width: 667px) {
            &::before {
               content: "";
               position: absolute;
               bottom: -0.2rem;
               left: 0;
               width: 100%;
               height: 2px;
               background: $accent;
            }
         }
      }
      &:hover {
         & > * {
            color: $accent;
         }
      }
      & > * {
         color: $primary-text-color;
         transition: color .25s $time-distribution-function;
      }
   }
   &__text {
      &:not(:last-child) {
         margin-bottom: 6rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 3rem;
         }
      }
      & > * {
         font-size: 1.8rem;
         line-height: 2.9rem;
         color: $title-color;
      }

   }
   &__sub-title {
      @extend %title-h4;
   }
   &__description {
      @media screen and (min-width: 667px) {
         flex: 0 1 60%;
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
      }
   }
}

.team {
   &__wrapper {
      position: relative;
      padding: 5rem 0 10rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 0;
         display: flex;
         flex-direction: column;
         align-items: center;
      }
   }
   &__title {
      @extend %title-h2;
      text-align: center;
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
   }
   &__text {
      max-width: 56rem;
      width: 100%;
      margin: 0 auto;
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         text-align: center;
      }
   }
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}

.slider-team {
   height: 55rem;
   min-width: 0; //fix bag
   @media screen and (max-width: 666px) {
      height: 48rem;
   }
   &__box {
      min-width: 0; //fix bag
      max-width: 55rem;
      width: 100%;
      padding-bottom: 5rem;
      position: relative;
      @media screen and (min-width: 667px) {
         margin: 5rem -10rem 0 11rem;
         max-width: 176rem;
         width: 100%;
         &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 83.2rem;
            height: 100%;
            background: linear-gradient(269.24deg, #F7F9FB 0.71%, #F7F9FB 6.88%, rgba(247, 249, 251, 0) 99.42%);
            z-index: 2;
            pointer-events: none;
         }
      }
      @media screen and (max-width: 666px) {
         margin: 2.5rem 0 0 0;
         padding-bottom: 3rem;
      }
   }
   &__slide {
      position: relative;
      height: 100%;
      display: flex;
      min-width: 0; //fix bag
      @media screen and (min-width: 667px) {
         max-width: 40.8rem;
         width: 100%;
      }
   }
   &__img {
      height: 100%;
      width: 100%;
   }
   &__inner {
      position: absolute;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      display: flex;
      flex-direction: column;
      background: rgba(166, 166, 166, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2rem);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 0.6rem;
      padding: 2rem;
      min-height: 18.3rem;
   }
   &__name {
      @extend %title-h3;
      text-align: center;
      &:not(:last-child) {
         margin-bottom: 1rem;
      }
      & > * {
         color: $white-text-color;
      }
   }
   &__position {
      &:not(:last-child) {
         margin-bottom: 1.6rem;
      }
      & > * {
         font-weight: 500;
         font-size: 1.2rem;
         line-height: 1.4rem;
         text-align: center;
         text-transform: uppercase;
         color: #E3EAF2;
      }
   }
   &__text {
      & > * {
         font-size: 1.4rem;
         line-height: 2.5rem;
         text-align: center;
         color: $white-text-color;
      }
   }
   &__button-prev {
      left: 0;
   }
   &__button-next {
      right: 0;
   }
   &__button-prev,
   &__button-next {
      width: 6.4rem;
      height: 6.4rem;
      background: rgba(131, 149, 167, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2rem);
      border-radius: 5rem;
      margin: 0;
      &::after {
         font-size: 1.7rem;
         font-weight: 600;
         color: $white-text-color;
      }
      @media screen and (max-width: 666px) {
         display: none;
      }
   }
   &__pagination {
      display: flex;
      align-items: center;
      bottom: 0 !important;
      @media screen and (max-width: 666px) {
         justify-content: center;
      }
      @media screen and (min-width: 667px) {
         width: auto !important;
      }
      & .swiper-pagination-bullet {
         width: 0.6rem;
         height: 0.6rem;
         &.swiper-pagination-bullet-active {
            background-color: $accent;
            width: 1rem;
            height: 1rem;
         }
      }
   }
}
/* ============================ about END ================================================================= */