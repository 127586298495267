@import '../app/variable.scss';
@import "../components/form/form.scss";
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ FAQ START ================================================================= */
.help {
   background: $bg-color;
   &__wrapper {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 666px) {
         flex-direction: column;
         padding: 2rem 0;
      }
      @media screen and (min-width: 667px) {
         padding: 0 0 6.8rem 15rem;
         align-items: center;
      }
   }
   &__content {
      max-width: 56rem;
      width: 100%;
      @media screen and (min-width: 667px) {
         margin-right: 1rem;
      }
      @media screen and (max-width: 666px) {
         margin-bottom: 2rem;
      }
   }
   &__title {
      &:not(:last-child) {
         margin-bottom: 3rem;
      }
      @extend %title-h1;
   }
   &__text {
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $gray-text;
      }
   }
   &__img {
      pointer-events: none;
      max-width: 74.6rem;
      width: 100%;
      @media screen and (min-width: 667px) {
         transform: translateY(-4%);
         height: 35.8rem;
      }
      & > * {
         @media screen and (max-width: 768px) {
            object-fit: contain;
         }
      }
   }
}

.info-faq {
   &__wrapper {
      display: flex;
      justify-content: space-between;
      padding: 10rem 0 10rem 15rem;
      @media screen and (max-width: 666px) {
         padding: 5rem 0;
         flex-direction: column;
         align-items: center;
      }
   }
   &__list {
      max-width: 86rem;
      width: 100%;
      @media screen and (min-width: 667px) {
         margin-right: 19rem;
      }
      @media screen and (max-width: 666px) {
         margin-bottom: 4rem;
      }
   }
   &__item {
      padding-bottom: 4rem;
      border-bottom: solid 1px #DFE6E9;
      @media screen and (max-width: 666px) {
         padding-bottom: 2rem;
      }
      &:not(:last-child) {
         margin-bottom: 4rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
   }
   &__box {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   &__title {
      cursor: pointer;
      @extend %title-h4;
   }
   &__text {
      max-width: 75rem;
      width: 100%;
      margin-top: 4rem;
      @media screen and (max-width: 666px) {
         margin-top: 2rem;
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $gray-text;
      }
   }
   &__btn {
      margin-left: 1rem;
      &.active {
         & .info-faq__icon_open {
            display: none;
         }
         & .info-faq__icon_close {
            display: block;
         }
      }
      & .info-faq__icon_close {
         display: none;
      }
   }
   &__icon {
      width: 3rem;
      height: 3rem;
      display: block;
      & > * {
         width: 3rem;
         height: 3rem;
         display: block;
      }
   }
   &__img {
      max-width: 56rem;
      width: 100%;
      @media screen and (min-width: 667px) {
         height: 73.7rem;
      }
   }
}
.info-faq + .form__container {
   @media screen and (min-width: 667px) {
      margin-bottom: 5rem;
   }
}
/* ============================ FAQ END ================================================================= */