@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ fence START ================================================================= */
.info-fence {
   background-color: $bg-color;
   &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 86rem;
      width: 100%;
      padding: 10rem 0;
      margin: 0 auto;
      @media screen and (max-width: 666px) {
         padding: 5rem 0;
      }
   }
   &__title {
      @extend %title-h2;
      &:not(:last-child) {
         margin-bottom: 4rem;
         @media screen and (max-width: 666px) {
            margin-bottom: 2rem;
         }
      }
   }
   &__text {
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.9rem;
         text-align: center;
         color: #57606F;
         & > a {
            text-decoration-line: underline;
            color: $link-color;
         }
      }
   }
}

.ornamental {
   &__wrapper {
      padding: 4rem 0 0;
      width: 100%;
      @media screen and (max-width: 666px) {
         padding: 2rem 0 0;
      }
      & > ul, ol {
         padding-left: 2rem;
         & > li {
            position: relative;
            &:not(:last-child) {
               margin-bottom: 1rem;
            }
            font-size: 1.6rem;
            line-height: 2.9rem;
            color: #57606F;
            &::before {
               content: "";
               position: absolute;
               top: 1.1rem;
               left: -2rem;
               width: 0.6rem;
               height: 0.6rem;
               background: $accent;
               border-radius: 50%;
            }
         }
      }
   }
   &__img {
      position: relative;
      min-height: 20rem;
      max-height: 20rem;
      height: 1px;
      width: 100%;
      &::before {
         content: "";
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         width: 100%;
         height: 100%;
         background-color: #000;
         opacity: 0.4;
      }
   }
   &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6rem;
      height: 6rem;
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (max-width: 666px) {
         flex-direction: column;
      }
      @media screen and (min-width: 667px) {
         margin: -4rem 0 0 -11.5rem;
      }
   }
   &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 41rem;
      @media screen and (max-width: 666px) {
         &:not(:last-child) {
            margin-bottom: 3rem;
         }
      }
      @media screen and (min-width: 667px) {
         width: calc(100% / 3 - 11.5rem);
         margin: 4rem 0 0 11.5rem;
      }
   }
   &__title {
      @extend %title-h3;
      &:not(:last-child) {
         margin-bottom: 2rem;
      }
   }
}

.design-fence {
   padding: 10rem 0;
   @media screen and (max-width: 666px) {
      padding: 5rem 0;
   }
   & + .form__container {
      @media screen and (min-width: 667px) {
         margin-bottom: 5rem;
      }
   }
}

.design-fence-slider {
   &__box {
      position: relative;
      max-width: 75rem;
      width: 100%;
      min-width: 0;
      @media screen and (min-width: 667px) {
         margin: 0 11rem 0 15rem;
         padding: 6rem 0 12.4rem 0;
      }
      @media screen and (max-width: 666px) {
         padding: 3rem 0;
      }
   }
   &__navigation-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      @media screen and (max-width: 666px) {
         display: none;
      }
   }
   &__button-prev{
      &:not(:last-child) {
         margin-right: 1.4rem;
      }
   }
   &__button-prev,
   &__button-next {
      position: static;
      width: 6.4rem;
      height: 6.4rem;
      margin: 0;
      border-radius: 5rem;
      border: solid 1px $accent;
      background-color: $white-text-color;
      transition: background-color .25s $time-distribution-function;
      &:hover {
         background-color: $accent;
         &::after {
            color: $white-text-color;
         }
      }
      &::after {
         font-size: 1.7rem;
         font-weight: 600;
         color: $accent;
         transition: color .25s $time-distribution-function;
      }
      &.swiper-button-disabled {
         background: #FEFEFE;
         border: 1px solid #F9DAD7;
         backdrop-filter: blur(2rem);
         /* Note: backdrop-filter has minimal browser support */
         border-radius: 5rem;
      }
   }
   &__pagination {
      display: flex;
      align-items: center;
      left: 0;
      @media screen and (max-width: 666px) {
         justify-content: center;
         top: auto;
         bottom: 0 !important;
      }
      @media screen and (min-width: 667px) {
         width: auto !important;
         bottom: auto !important;
         top: 0;
      }
      & .swiper-pagination-bullet {
         width: 0.6rem;
         height: 0.6rem;
         &.swiper-pagination-bullet-active {
            background-color: $accent;
            width: 1rem;
            height: 1rem;
         }
      }
   }
}
/* ============================ fence END ================================================================= */