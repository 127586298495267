@import '../app/variable.scss';
@import '../app/placeholder.scss';
@import "../components/breadcrumbs/breadcrumbs.scss";

/* ============================ examples START ================================================================= */
.gallery-examples {
   &__wrapper {
      display: flex;
      padding: 10rem 0;
      @media screen and (max-width: 666px) {
         padding: 5rem 0;
         flex-direction: column;
      }
   }
}

.sidebar {
   @media screen and (min-width: 667px) {
      margin-right: 4rem;

   }
   &__box {
      min-width: 0; //fix bag
      @media screen and (max-width: 666px) {
         margin: 0 auto 3rem;
         width: 100%;
      }
      @media screen and (min-width: 667px) {
         max-width: 41rem;
         width: 100%;
         & .swiper-wrapper {
            display: block;
         }
      }
   }
   &__wrapper {
      @media screen and (max-width: 666px) {
         margin-bottom: 3rem;
      }
   }
   &__item {
      &:not(:last-child) {
         @media screen and (min-width: 667px) {
            margin-bottom: 5rem;
         }
      }
   }
   &__title {
      position: relative;
      display: inline-block;
      @extend %title-h4;
      @media screen and (min-width: 667px) {
         cursor: pointer;
      }
      & > * {
         transition: color .25s $time-distribution-function;
      }
      &.open {
         &::before {
            content: "";
            position: absolute;
            bottom: -1.6rem;
            left: 0;
            width: 5rem;
            height: 1px;
            background-color: $accent;
         }
         & > * {
            color: $accent;
         }
      }
      &:hover {
         & > * {
            color: $accent;
         }
      }
   }
   &__text {
      margin-top: 4.5rem;
      @media screen and (max-width: 666px) {
         margin-top: 2rem;
      }
      & > * {
         font-size: 1.6rem;
         line-height: 2.6rem;
         color: $secondary;
      }
   }
   &__pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0 !important;
      & .swiper-pagination-bullet {
         width: 0.6rem;
         height: 0.6rem;
         &.swiper-pagination-bullet-active {
            background-color: $accent;
            width: 1rem;
            height: 1rem;
         }
      }
      @media screen and (min-width: 667px) {
         display: none;
      }
   }
}

.gallery-slider {
   &__box {
      width: 100%;
      @media screen and (min-width: 667px) {
         & .swiper-wrapper {
            display: block;
         }
      }
   }
}

.gallery {
   display: flex;
   flex-wrap: wrap;
   margin: -4rem 0 0 -4rem;
   @media screen and (min-width: 667px) and (max-width: 991px) {

   }
   @media screen and (max-width: 666px) {
      margin: 0;
   }
   @media screen and (max-width: 479px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
   }
   &__wrapper {
      display: block;
   }
   &__item {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3 - 4rem);
      margin: 4rem 0 0 4rem;
      cursor: zoom-in;
      @media screen and (min-width: 667px) and (max-width: 991px) {
         width: calc(100% / 2 - 4rem);
      }
      @media screen and (max-width: 666px) {
         margin: 1.5rem 0 0 1.5rem;
         width: calc(100% / 2 - 1.5rem);
      }
      @media screen and (max-width: 479px) {
         max-width: 33rem;
         width: 100%;
         margin: 0;
         &:not(:last-child) {
            margin-bottom: 2rem;
         }
      }
   }
   &__img {
      max-height: 53.6rem;
      min-height: 53.6rem;
      height: 1px;
      @media screen and (max-width: 666px) {
         max-height: 30rem;
         min-height: 30rem;
      }
   }
}
/* ============================ examples END ================================================================= */